import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetIP from "../components/IPServer";
import TicketPDF from "../components/TicketPDF";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import Loading from "../components/Loading";
const Paid = () => {
  const { userId } = useParams();
  const [ticketRecords, setTicketRecords] = useState([]);
  const [eventRecord, setEventRecord] = useState();
  const [userRecord, setUserRecord] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(
    () => {
      async function fetchData() {
        let filter = encodeURIComponent(JSON.stringify({ _id: userId }));
        let userRecord = await (
          await fetch(`${GetIP}?collection=users&filter=${filter}`)
        ).json();
        userRecord = userRecord[0];
        if (userRecord) {
          setUserRecord(userRecord);
          filter = encodeURIComponent(
            JSON.stringify({ _id: userRecord.eventId })
          );
          let eventRecord = await (
            await fetch(`${GetIP}?collection=events&id=${filter}`)
          ).json();
          eventRecord = eventRecord[0];
          if (eventRecord) {
            setEventRecord(eventRecord);
            filter = encodeURIComponent(
              JSON.stringify({ checkoutCode: userRecord.checkoutCode })
            );
            const ticketRecords = await (
              await fetch(`${GetIP}?collection=tickets&filter=${filter}`)
            ).json();
            if (ticketRecords.length > 0) {
              setTicketRecords(ticketRecords);
              setLoading(false);
            }
          }
        }
      }
      fetchData();
      return;
    },
    userId,
    [ticketRecords.length]
  );
  if (loading)
    return (
      <>
        <Loading></Loading>
      </>
    );
  return (
    <>
      {/* <div className='container' style={{ height: '200px' }}>
                <PDFDownloadLink
                    document={
                        <TicketPDF
                            userRecord={userRecord}
                            ticketRecords={ticketRecords}
                            eventRecord={eventRecord}
                        />
                    }
                    fileName="Tickets.pdf"
                    style={{ textDecoration: "none", color: "white" }} className="btn jn-buy-button ju-waves rounded-pill" >
                    {({ blob, url, loading, error }) => {
                        if (!loading) {
                            var onetime = true;
                            if (url && onetime) {
                                const a = document.createElement('a');
                                a.href = url;
                                //sa.download = 'Tickets.pdf'; // Establece el nombre del archivo aquí
                                a.click();
                                //window.location.href = "/";
                                return;
                            }
                        }
                    }}
                </PDFDownloadLink>
            </div> */}
      <div
        className=" h6 container d-grid justify-content-center align-items-center text-center"
        style={{ marginTop: "160px" }}
      >
        Puede descargar sus entradas desde el siguiente enlace:
      </div>
      <div
        className="container d-grid justify-content-center align-items-center"
        style={{ marginTop: "50px", marginBottom: "160px" }}
      >
        <PDFDownloadLink
          document={
            <TicketPDF
              userRecord={userRecord}
              eventRecord={eventRecord}
              ticketRecords={ticketRecords}
            />
          }
          fileName="Entradaspe - tickets.pdf"
          style={{ textDecoration: "none", color: "white" }}
          className="btn jn-buy-button ju-waves rounded-pill"
        >
          {({ blob, url, loading, error }) =>
            loading ? "Cargando..." : "Descargar entradas"
          }
        </PDFDownloadLink>
      </div>
    </>
  );
};

export default Paid;
