import React, { useState, useEffect } from "react";
import mapsPreview from "../assets/maps-preview.png";
import GetIP from "./IPServer";
import Loading from "./Loading";
const { DateTime } = require("luxon");

function str2hex(str) {
  let hex = "";
  for (var i = 0; i < str.length; i++) {
    hex += "" + str.charCodeAt(i).toString(16);
  }
  return hex;
}

function checkTickets(t) {
  if (t.length == 0) return false;
  var p = 0;
  for (let i = 0; i < t.length; i++) p += t[i];
  if (p == 0) return false;
  return true;
}

async function createUserRecord(eventRecord, nTickets, totalPrice) {
  try {
    const datetime = await (
      await fetch("https://worldtimeapi.org/api/timezone/America/Lima")
    ).json();
    //const dtNow = DateTime.now().setZone("America/Lima");
    const data = {
      options: "reduceEntries",
      document: {
        eventId: eventRecord._id,
        name: "",
        dni: "",
        cel: "",
        confirmationCode: (
          Math.floor(Math.random() * 90000000) + 10000000
        ).toString(),
        valid: false,
        dtGenerated: datetime.datetime,
        nTickets: nTickets,
        pricing: eventRecord.price,
        price: totalPrice,
        checkoutCode: "",
        paid: false,
        dtPaid: "",
        dtPromised: "",
        dtEvent: eventRecord.datetime,
        step: 1,
      },
    };
    const response = await fetch(`${GetIP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(response);
    if (!response.ok) {
      const message = `${await response.text()}`;
      window.alert(message);
      return;
    }
    const jsonResponse = await response.json();
    window.location.href = `/checkout/${str2hex(
      jsonResponse.insertedId + "," + datetime.datetime
    )}`;
  } catch (error) {
    console.log(error.toString());
    alert("a");
  }
}

const TicketEvent = (props) => {
  const { eventRecord } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [notice, setNotice] = useState(false);
  const [nTickets, setNTickets] = useState(Array(10).fill(0));
  const aumentarEntrada = (n) => {
    setNTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      const p = newTickets[n];
      newTickets[n] = p >= eventRecord.max ? eventRecord.max : p + 1;
      totalPricing(newTickets);
      setNotice(false);
      return newTickets;
    });
  };
  const disminuirEntrada = (n) => {
    setNTickets((prevTickets) => {
      const newTickets = [...prevTickets];
      const p = newTickets[n];
      newTickets[n] = p <= 0 ? 0 : p - 1;
      totalPricing(newTickets);
      setNotice(false);
      return newTickets;
    });
  };
  const totalPricing = (newTickets) => {
    setTotalPrice((prevTotal) => {
      var newTotal = prevTotal;
      newTotal = 0;
      for (let i = 0; i < eventRecord.price.length; i++) {
        newTotal = newTotal + newTickets[i] * eventRecord.price[i];
      }
      return newTotal;
    });
  };

  const goToCheckout = () => {
    if (checkTickets(nTickets)) {
      let totalTickets = [];
      for (let i = 0; i < eventRecord.price.length; i++) {
        totalTickets.push(nTickets[i]);
      }
      createUserRecord(eventRecord, totalTickets, totalPrice);
    } else {
      setNotice(true);
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  const checkVisibility = () => {
    const elemento = document.getElementById("ageDiv");
    if (elemento) {
      const rect = elemento.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      setIsVisible(isVisible);
    }
  };
  useEffect(() => {
    checkVisibility();
    const interval = setInterval(() => {
      checkVisibility();
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="col-12 col-md-6 col-xl-4">
      <div className="border-0 mb-0 bg-white shadow-sm"></div>
      <div className="card border-0 shadow-sm mb-3 overflow-hidden">
        <div className="pb-3">
          <div className="p-3">
            <div>
              <h5 className="mb-4 text-dark text-capitalize d-none d-md-block">
                <b>{eventRecord.title}</b>
              </h5>
              <div>
                <div className="row mb-3">
                  <div className="col-3">
                    <div className="text-dark">Fecha</div>
                  </div>
                  <div className="col">{eventRecord.date}</div>
                </div>
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="text-dark">Horario</div>
                  </div>
                  <div className="col">{eventRecord.time}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <h5 className="mb-0">
              <b className="text-dark">Entradas</b>
            </h5>
          </div>
          <div>
            <ul className="list-unstyled m-0 border-form-types">
              {Array.from({ length: eventRecord.price.length }, (_, index) => (
                <li className="px-3 py-2 mb-1 bg-light" key={index}>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-12">
                      <div className="row mb-1">
                        <div className="col-10">
                          <h6 className="mb-0">
                            <b>
                              {
                                eventRecord.type[
                                  index % eventRecord.type.length
                                ]
                              }
                            </b>
                          </h6>
                        </div>
                        <div className="col-2 text-right">
                          {/* <h6 className='mb-0'></h6> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row align-items-center">
                        <div className="col text-left">
                          <div>
                            <b>
                              S/{" "}
                              {
                                eventRecord.price[
                                  index % eventRecord.price.length
                                ]
                              }
                            </b>
                          </div>
                        </div>
                        <div className="col-auto text-right ">
                          <div>
                            <div className="text-nowrap">
                              <span className="d-flex align-items-center">
                                <button
                                  href="#"
                                  className="btnDark  btnTicketStyle"
                                  onClick={() => disminuirEntrada(index)}
                                >
                                  -
                                </button>
                                <span
                                  className="mx-2 text-dark text-center"
                                  style={{ width: "16px" }}
                                >
                                  {nTickets[index % nTickets.length]}
                                </span>
                                <button
                                  href="#"
                                  className="btnDanger btnTicketStyle"
                                  onClick={() => aumentarEntrada(index)}
                                >
                                  +
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className={`col-12 text-center colorRed ${
              notice ? "d-block" : "d-none"
            }`}
          >
            Debe seleccionar por lo menos un ticket
          </div>
          <div className="pb-1 pt-2 px-4">
            <div className="custom-control custom-checkbox c-pointer small">
              <input
                type="checkbox"
                className="custom-control-input"
                value="true"
                id="form-invoice2"
                defaultChecked
                onChange={() => {
                  document.getElementById("btnComprar").disabled =
                    !document.getElementById("btnComprar").disabled;
                }}
              ></input>
              <label className="custom-control-label" htmlFor="form-invoice2">
                Te informamos que tus datos personales seran compartidos con el
                organizador del evento
              </label>
            </div>
          </div>
          <div className="small py-2 px-4" id="ageDiv">
            {eventRecord.ageRes
              ? "*Apto solo para mayores de 18 años."
              : "*Apto para publico en general."}
          </div>
          <div className={`py-3 px-4 ${totalPrice > 0 ? "d-block" : "d-none"}`}>
            <div className="h5 row">
              <div className="col text-left text-dark">
                <b>Total</b>
              </div>
              <div className="col text-right text-dark">
                <b>S/ {totalPrice}</b>
              </div>
            </div>
          </div>
          <div></div>
          <div className={`tbtn-fixed py-2 px-4 ${isVisible ? "" : "active"}`}>
            <div className="container">
              <div className="form-row align-items-center justify-content-between">
                <div className="col tbtn-fixed__title">
                  <strong className="h5 mb-0 py-3 d-md-block">
                    {eventRecord.title}
                  </strong>
                </div>
                <div className="col-12 tbtn-fixed__btn">
                  <button
                    className="btn btn-danger btn-block rounded-pill"
                    type="button"
                    id="btnComprar"
                    onClick={() => goToCheckout()}
                  >
                    <div className="m-0 py-1 h5">
                      <b>Comprar ahora</b>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card border-0 shadow-sm mb-3 overflow-hidden">
        <div className="card-body py-2">
          <h5 className="mb-1 text-capitalize">
            <b>Lima, PE</b>
          </h5>
          <h6>{eventRecord.place}</h6>
          <p className="small mb-0">
            <strong></strong>
          </p>
        </div>
        <a
          href={eventRecord.location_url}
          className="maps-preview"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mapsPreview} alt="map" className="img-fluid" />
          <span className="btn btn-danger btn-sm">Ver mapa</span>
        </a>
      </div>
      <div className="card border-0 shadow-sm mb-3 p-1">
        <div className="row no-gutters align-items-center">
          <div className="col-auto">
            <img
              className="rounded"
              src={eventRecord.organizer_image}
              width={48}
              height={48}
              alt="organizer"
            />
          </div>
          <div className="col">
            <div className="card-body py-2">
              <h6 className="card-title mb-0">
                <span>Organiza</span>
                <br></br>
                <a
                  href={eventRecord.organizer_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="orgnizer-url"
                >
                  <b>{eventRecord.organizer_name}</b>
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TicketEvent;
