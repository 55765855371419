import React from "react";
import { useNavigate } from 'react-router-dom';
import GetIP from "../components/IPServer";
import SmallLoading from "../components/SmallLoading";
import { createRoot } from "react-dom/client";
import { scanner } from "fontawesome";
const Login = () => {
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        createRoot(document.getElementById('result')).render(<SmallLoading></SmallLoading>)
        const username = event.target[0].value
        const password = event.target[1].value
        const filter = encodeURIComponent(JSON.stringify({username: username, password: password}));
        let response = await fetch(`${GetIP}?collection=scanners&filter=${filter}`);
        if (!response.ok) {
            document.getElementById('result').innerHTML = "Datos no validos";
            return
        }
        let scannerRecord = await response.json()
        scannerRecord = scannerRecord[0]
        const actualDate = new Date().getTime();
        const loginDate = scannerRecord.lastLogin;
        if (loginDate) {
            if (actualDate - loginDate <= 1000 * 15) {
                document.getElementById('result').innerHTML = "La cuenta esta iniciada en otro dispositivo";
                return
            }
        }
        let data = {
            options: "newLogin",
            id: scannerRecord._id,
            lastLogin: new Date().getTime()
        }
        response = await fetch(`${GetIP}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

        if (!response.ok) {
            document.getElementById('result').innerHTML = "No se pudo iniciar sesion, intente nuevamente";
            return
        }
        scannerRecord = await response.json()
        scannerRecord.lastLogin += 30000;
        navigate('/escaner/main', { state: { scannerRecord } });
    }

    return <>
        <div className='container d-grid justify-content-center p-0' style={{ marginTop: '50px', marginBottom: '100px' }}>
            <div className='h4 text-center my-5' style={{ width: "400px" }}> Ingrese sus credenciales</div>
            <div className='container py-2 d-grid' style={{ background: '#e5e5e5', borderRadius: '15px', maxWidth: '400px' }}>
                <form onSubmit={handleSubmit}>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Usuario</label>
                        <input title='Debe ingresar el numero de su entrada' input='text' required={true} placeholder='' className='text-center' minLength={0} maxLength={20}></input>
                    </div>

                    <div className='container d-grid my-3'>
                        <label className='my-2'>Contraseña</label>
                        <input title='Debe ingresar el numero de su entrada' type='password' required={true} placeholder='' className='text-center' minLength={0} maxLength={20}></input>
                    </div>

                    <div className='d-grid justify-content-center my-3'>
                        <h6 id='result'> </h6>
                    </div>
                    <div className='d-grid justify-content-center my-3'>
                        <button className='btn btn-danger' style={{ width: "200px" }}>Entrar</button>
                    </div>
                </form>
            </div>
        </div>
    </>
}
export default Login