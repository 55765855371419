import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import ticketImage from "../assets/ticket.png";
import ticketImage24H from "../assets/ticketFinal.png";
import ticketImageTransfer from "../assets/ticketTransfer.png";
import QRCode from "qrcode.react";
const TicketPdf = (props) => {
  const { ticketRecords, userRecord, eventRecord } = props;
  const is24H = () => {
    const actualDate = new Date();
    const eventDate = new Date(eventRecord.datetime);
    if (eventDate - actualDate < 24 * 60 * 60 * 1000) return true;
    return false;
  };
  const isTransfered = (record) => {
    if (record.dtTransferDone != null) return true;
    return false;
  };
  const isPaid = (record) => {
    if (record.paid) return true;
    return false;
  };
  const generatePages = () => {
    return ticketRecords.map((record, index) => (
      <Page key={index} size={{ width: 600, height: 1000 }}>
        <View style={{ position: "relative", width: "100%", height: "100%" }}>
          <Image
            src={
              isTransfered(record)
                ? ticketImageTransfer
                : isPaid(record)
                ? is24H()
                  ? ticketImage24H
                  : ticketImage
                : ticketImage
            }
            style={{ height: "100%", display: `block` }}
          ></Image>
          <Image
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${record._id}`}
            style={{
              position: "absolute",
              top: "17.3%",
              left: "30%",
              width: "40%",
              display: `${
                isTransfered(record) ? "none" : is24H() ? "block" : "none"
              }`,
            }}
          ></Image>
          <Text
            style={{
              position: "absolute",
              top: "8.7%",
              left: "48%",
              color: "white",
              fontSize: "28px",
            }}
          >
            {record.ticketId}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "47%",
              left: "42%",
              color: "#000000",
              fontSize: "22px",
            }}
          >
            {userRecord.name}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "52%",
              left: "15%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {userRecord.dni}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "54.77%",
              left: "20%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {eventRecord.place}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "57.54%",
              left: "25%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {eventRecord.location_url}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "60.17%",
              left: "20%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {eventRecord.date}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "63.1%",
              left: "20%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {eventRecord.time}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "67.14%",
              left: "40%",
              color: "#000000",
              fontSize: "22px",
            }}
          >
            {record.type}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "72.2%",
              left: "25%",
              color: "#000000",
              fontSize: "16px",
            }}
          >
            {index + 1}/{ticketRecords.length}{" "}
          </Text>
          <Text
            style={{
              position: "absolute",
              top: "75%",
              left: "20%",
              color: "red",
              fontSize: "16px",
            }}
          >
            S/. {userRecord.price}{" "}
          </Text>
        </View>
      </Page>
    ));
  };

  return <Document>{generatePages()}</Document>;
};
export default TicketPdf;
