import Button from "../components/Button";
import React, { useState } from "react";
import { Header, Footer } from "../components/global/Components";
const Administration = () => {
  const [show, setShow] = useState(0);
  return (
    <>
      <div className="d-sm-block d-none">
        <Header source="naked" />

        <div
          className="col-12 d-flex justify-content-center "
          style={{ height: "40.73vw" }}
        >
          <div className="col-3"></div>
          <div className="col-9">
            <div
              className="d-grid"
              style={{
                height: "32.97vw",
                width: "48.39vw",
                borderRadius: "74px",
                boxShadow: "rgba(0,0,0,0.5) 1px 2px 2px 2px",
                marginTop: "3.85vw",
                marginRight: "0.36vw",
                position: "absolute",
              }}
            ></div>

            <div
              className="league-spartan-400"
              style={{
                fontSize: "3.13vw",
                marginTop: "3.85vw",
                marginRight: "0.36vw",
                position: "absolute",
                paddingLeft: "10.21vw",
              }}
            >
              <div className="d-flex" style={{ marginTop: "5.31vw" }}>
                <div
                  className="admin-btn"
                  onMouseEnter={() => {
                    setShow(1);
                  }}
                  onMouseLeave={() => {
                    setShow(0);
                  }}
                >
                  {" "}
                  Valida tu entrada
                </div>
                <div
                  className={show === 1 ? "d-flex" : "d-none"}
                  style={{
                    marginLeft: "1.25vw",
                    paddingLeft: "1.04vw",
                    paddingTop: "1.3vw",
                    paddingRight: "0.78vw",
                    height: "4.17vw",
                    width: "13.07vw",
                    borderRadius: "12px",
                    boxShadow: "rgba(0,0,0,0.5) 1px 2px 2px 2px",
                    fontSize: "1.04vw",
                    lineHeight: "0.9vw",
                    textAlign: "justify",
                    backgroundColor: "white",
                  }}
                >
                  <span style={{ color: "#ff0043", fontSize: "2.08vw" }}>
                    {" "}
                    *
                  </span>
                  <span style={{ marginTop: "-0.5vw" }}>
                    Aquí verificaras si la entrada que tienes es valida
                  </span>
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "5.31vw" }}>
                <div
                  className="admin-btn"
                  style={{ fontSize: "3.02vw" }}
                  onMouseEnter={() => {
                    setShow(2);
                  }}
                  onMouseLeave={() => {
                    setShow(0);
                  }}
                >
                  {" "}
                  Transfiere tu entrada
                </div>
                <div
                  className={show === 2 ? "d-flex" : "d-none"}
                  style={{
                    marginLeft: "1.25vw",
                    paddingLeft: "1.04vw",
                    paddingTop: "1.3vw",
                    paddingRight: "0.78vw",
                    height: "5.26vw",
                    width: "13.07vw",
                    borderRadius: "12px",
                    boxShadow: "rgba(0,0,0,0.5) 1px 2px 2px 2px",
                    fontSize: "1.04vw",
                    lineHeight: "0.9vw",
                    textAlign: "justify",
                    backgroundColor: "white",
                  }}
                >
                  <span style={{ color: "#ff0043", fontSize: "2.08vw" }}>
                    {" "}
                    *
                  </span>
                  <span style={{ marginTop: "-0.5vw" }}>
                    Aquí podrás vender. regalar o transferir tu entrada en forma
                    segura.
                  </span>
                </div>
              </div>
              <div className="d-flex" style={{ marginTop: "5.31vw" }}>
                <div
                  className="admin-btn"
                  style={{ fontSize: "2.81vw" }}
                  onMouseEnter={() => {
                    setShow(3);
                  }}
                  onMouseLeave={() => {
                    setShow(0);
                  }}
                >
                  {" "}
                  Recibe tu transferencia
                </div>
                <div
                  className={show === 3 ? "d-flex" : "d-none"}
                  style={{
                    marginLeft: "1.25vw",
                    paddingLeft: "1.04vw",
                    paddingTop: "1.3vw",
                    paddingRight: "0.78vw",
                    height: "4.95vw",
                    width: "13.07vw",
                    borderRadius: "12px",
                    boxShadow: "rgba(0,0,0,0.5) 1px 2px 2px 2px",
                    fontSize: "1.04vw",
                    lineHeight: "0.9vw",
                    textAlign: "justify",
                    backgroundColor: "white",
                  }}
                >
                  <span style={{ color: "#ff0043", fontSize: "2.08vw" }}>
                    {" "}
                    *
                  </span>
                  <span style={{ marginTop: "-0.5vw" }}>
                    Aquí podrás recibir tu entrada transferida, en forma segura
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer source="naked" />
      </div>
    </>
  );
};
export default Administration;
