import React from 'react';
import GetIP from '../components/IPServer';
import SmallLoading from "../components/SmallLoading"
import { createRoot } from 'react-dom/client'
function Verificar() {
    const handleSubmit = async (event) => {
        document.getElementById('result').style = ""
        createRoot(document.getElementById('result')).render(<SmallLoading></SmallLoading>)
        let msg = "";
        event.preventDefault();
        const ticketid = event.target[0].value.split("-")
        if (ticketid[0] != "001001") {
            document.getElementById('result').innerHTML = "Datos no validos";
            return;
        }
        const dni = event.target[1].value
        let response = await fetch(`${GetIP}?ticketId=${ticketid[1]}&dni=${dni}&collectionName=tickets&options=verifyTicket`);
        if (!response.ok) {
            document.getElementById('result').innerHTML = "Datos no validos"
            return;
        }
        const ticketRecord = await response.json()
        response = await fetch(`${GetIP}?collectionName=events&options=getone&id=${ticketRecord.eventId}`);
        if (!response.ok) {
            document.getElementById('result').innerHTML = "Datos no validos"
            return;
        }
        const eventRecord = await response.json()
        document.getElementById('result').innerHTML = "Entrada no valida o en proceso de pago"
        document.getElementById('result').style = "background:red"
        if (!ticketRecord.paid) return;
        if (ticketRecord.used) return;
        if (ticketRecord.dtTransferDone != "") return
        document.getElementById('result').innerHTML = `Entrada valida para<br>${eventRecord.title}<br>Zona: ${ticketRecord.type}`;
        document.getElementById('result').style = "background:lime"
    }

    return (
        <>
            <div className='container d-grid justify-content-center p-0' style={{ marginTop: '65px' }}>
                <div className='h4 text-center'> Verifica el estado de tu entrada ingresando los siguientes datos:</div>
                <div className='container py-2 d-grid' style={{ background: '#e5e5e5', borderRadius: '15px', maxWidth: '400px' }}>
                    <form onSubmit={handleSubmit}>
                        <div className='container d-grid my-3'>
                            <label className='my-2'>Numero de entrada</label>
                            <input title='Debe ingresar el numero de su entrada' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={12} maxLength={12}></input>
                        </div>
                        <div className='container d-grid my-3'>
                            <label className='my-2'>DNI</label>
                            <input title='Debe ingresar el numero de su DNI' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={8} maxLength={8}></input>
                        </div>
                        <div className='d-grid justify-content-center my-3'>
                            <div id='result' className='h6 text-center lh-lg'> </div>
                        </div>
                        <div className='d-grid justify-content-center my-3'>
                            <button className='btn btn-danger'>Consultar</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Verificar;