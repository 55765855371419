import React, { useState, useEffect } from "react";
import "../../styles/bootstrap.min.css";
import headerLogo from "../../assets/headerlogo.png";
import usergear from "../../assets/user-gear.png";
import logosmall from "../../assets/logosmall.png";
import whatsapp from "../../assets/whatsapp.png";
import telegram from "../../assets/telegram.png";
import facebook from "../../assets/iconos rrss-01.png";
import youtube from "../../assets/iconos rrss-02.png";
import linkedin from "../../assets/iconos rrss-03.png";
import instagram from "../../assets/iconos rrss-04.png";
import administrarentradas from "../../assets/administrarentradas.png";
import twitter from "../../assets/iconos rrss-05.png";
import mail from "../../assets/mail.png";
import reclamos from "../../assets/reclamos.png";
import logofooter from "../../assets/logofooter.png";
import loadingLogo from "../../assets/loading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";
import icon1 from "../../assets/Icono Menu-01.png";
import icon5 from "../../assets/Icono Menu-05.png";
import icon2 from "../../assets/Icono Menu-02.png";
import icon6 from "../../assets/Icono Menu-06.png";
import icon3 from "../../assets/Icono Menu-03.png";
import icon7 from "../../assets/Icono Menu-07.png";
import icon4 from "../../assets/Icono Menu-04.png";
import icon8 from "../../assets/Icono Menu-08.png";

export const Header = (props) => {
  const { source } = props;
  const [change, setChange] = useState(false);
  const [changeMobile, setChangeMobile] = useState(false);
  const [hover, setHover] = useState([false, false, false, false]);

  useEffect(() => {
    console.log(change);
  }, [change]);

  if (source === "home") {
    return (
      <>
        {/* Pantalla pc mayores a 576px */}
        <div
          className="col-12 league-spartan-500 d-sm-flex d-none align-items-center justify-content-center"
          style={{
            background: "linear-gradient(to right, #ff0043, #000000)",
            height: "3.59vw",
          }}
        >
          <div className="col-2"></div>
          <div className="col-1 d-flex align-items-center justify-content-center">
            <img
              className="hover-pointer"
              src={headerLogo}
              onClick={() => {
                window.location.href = "/"; //Va a la pagina principal
              }}
              alt=""
              style={{ height: "1.93vw", width: "8.18vw" }}
            ></img>
          </div>
          <div className="col-5"></div>
          <div className="col-2 d-flex align-items-center justify-content-center">
            <button
              className="d-flex align-items-center justify-content-center"
              style={{
                fontSize: "1.3vw",
                height: "2.08vw",
                width: "16.35vw",
                borderRadius: "100px",
                backgroundColor: "#ff0043",
                color: "white",
                borderStyle: "none",
              }}
              onClick={() => {
                setChange(!change);
                //window.location.href = "/administrar";
                //console.log("s");
              }}
            >
              Administrar Entradas&nbsp;
              {(() => {
                if (!change)
                  return (
                    <>
                      <FontAwesomeIcon icon={faCircleChevronDown} />
                    </>
                  );
                else
                  return (
                    <>
                      <FontAwesomeIcon icon={faCircleChevronUp} />
                      <div
                        className="league-spartan-500 col-2"
                        style={{
                          marginTop: "14.5vw",
                          height: "9.74vw",
                          borderRadius: "20px",
                          backgroundColor: "white",
                          position: "absolute",
                          zIndex: "2",
                          color: "#818181",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          fontSize: "1.04vw",
                          paddingLeft: "1.36vw",
                          paddingRight: "1.36vw",
                          boxShadow: "rgba(0,0,0,0.5) 1px 2px 2px 2px",
                        }}
                      >
                        <div className="col-12 h-25 d-grid align-items-center">
                          <div
                            className="d-flex admin-title"
                            onClick={() => {
                              window.location.href = "/resumen";
                            }}
                            onMouseEnter={() => {
                              setHover([1, 0, 0, 0]);
                            }}
                            onMouseLeave={() => {
                              setHover([0, 0, 0, 0]);
                            }}
                          >
                            <div className="col-3">
                              {" "}
                              <img
                                className="iconmenu-pe"
                                src={hover[0] ? icon8 : icon4}
                                alt=""
                              />
                            </div>

                            <div
                              className="col-9"
                              style={{ textAlign: "start" }}
                            >
                              Resumen de compra
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-12 h-25 d-grid align-items-center"
                          style={{
                            borderTopStyle: "solid",
                            borderWidth: "1px",
                          }}
                        >
                          <div
                            className="d-flex admin-title"
                            onClick={() => {
                              window.location.href = "/validar";
                            }}
                            onMouseEnter={() => {
                              setHover([0, 1, 0, 0]);
                            }}
                            onMouseLeave={() => {
                              setHover([0, 0, 0, 0]);
                            }}
                          >
                            <div className="col-3">
                              <img
                                className="iconmenu-pe"
                                src={hover[1] ? icon6 : icon2}
                                alt=""
                              />
                            </div>
                            <div
                              className="col-9"
                              style={{ textAlign: "start" }}
                            >
                              Valida tu entrada
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-12 h-25 d-grid align-items-center"
                          style={{
                            borderTopStyle: "solid",
                            borderWidth: "1px",
                          }}
                        >
                          <div
                            className="d-flex admin-title"
                            onClick={() => {
                              window.location.href = "/transferir";
                            }}
                            onMouseEnter={() => {
                              setHover([0, 0, 1, 0]);
                            }}
                            onMouseLeave={() => {
                              setHover([0, 0, 0, 0]);
                            }}
                          >
                            <div className="col-3">
                              <img
                                className="iconmenu-pe"
                                src={hover[2] ? icon5 : icon1}
                                alt=""
                              />
                            </div>
                            <div
                              className="col-9"
                              style={{ textAlign: "start" }}
                            >
                              Transfiere tu entrada
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-12 h-25 d-grid align-items-center"
                          style={{
                            borderTopStyle: "solid",
                            borderWidth: "1px",
                          }}
                        >
                          <div
                            className="d-flex admin-title"
                            onClick={() => {
                              window.location.href = "/recibir";
                            }}
                            onMouseEnter={() => {
                              setHover([0, 0, 0, 1]);
                            }}
                            onMouseLeave={() => {
                              setHover([0, 0, 0, 0]);
                            }}
                          >
                            <div className="col-3">
                              <img
                                className="iconmenu-pe"
                                src={hover[3] ? icon7 : icon3}
                                alt=""
                              />
                            </div>
                            <div
                              className="col-9"
                              style={{ textAlign: "start" }}
                            >
                              Recibe tu transferencia
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
              })()}
            </button>
          </div>
          <div className="col-2"></div>
        </div>

        <div></div>

        {/* Pantalla pc menores a 576px */}
        <div
          className="col-12 league-spartan-500 d-flex d-sm-none"
          style={{
            background: "linear-gradient(to right, #ff0043, #000000)",
            height: "13.33vw",
          }}
        >
          <div className="col-2 d-flex align-items-center justify-content-center">
            <img
              src={logosmall}
              onClick={() => {
                window.location.href = "/";
              }}
              style={{
                height: "9.16vw",
                width: "11.39vw",
                marginLeft: "4.17vw",
              }}
              alt=""
            ></img>
          </div>
          <div className="col-4 d-flex"></div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <button
              className="league-spartan-400"
              style={{
                color: "white",
                fontSize: "3.61vw",
                height: "6.11vw",
                width: "46.39vw",
                marginRight: "4.00vw",
                borderRadius: "100px",
                backgroundColor: "#ff0043",
                borderStyle: "none",
                paddingLeft: "1.7vw",
                paddingRight: "1.7vw",
              }}
              onClick={() => {
                //window.location.href = "/administrar";
                setChangeMobile(true);
                console.log("s");
              }}
            >
              Administrar Entradas{" "}
              <FontAwesomeIcon style={{ paddingTop: "0.7vw" }} icon={faBars} />
            </button>
          </div>

          <div
            className="col-12"
            style={{
              display: changeMobile ? "flex" : "none",
              width: "100%",
              height: "100%",
              position: "fixed",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: "3",
            }}
          >
            <div className="col-2"></div>
            <div
              className="col-10 d-block justify-content-center"
              style={{
                height: "100%",
                borderTopLeftRadius: "40px",
                borderBottomLeftRadius: "40px",
                backgroundColor: "white",
                color: "#818181",
              }}
            >
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                  paddingRight: "8.33vw",
                  marginTop: "5.56vw",
                  fontSize: "6.67vw",
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => {
                    setChangeMobile(false);
                  }}
                />
              </div>

              <div
                className="d-flex justify-content-center"
                style={{
                  marginTop: "5.56vw",
                  width: "100%",
                  height: "11.11vw",
                }}
              >
                <img
                  src={administrarentradas}
                  style={{ width: "80%", height: "100%" }}
                  alt=""
                ></img>
              </div>

              <div
                className="league-spartan-500 d-flex justify-content-center"
                style={{
                  color: "#818181",
                  marginTop: "12.5vw",
                  width: "100%",
                  height: "10vw",
                  fontSize: "5.56vw",
                }}
              >
                <div
                  className="d-flex justify-content-start admin-title"
                  style={{ width: "80%" }}
                  onClick={() => {
                    window.location.href = "/resumen";
                  }}
                  onMouseEnter={() => {
                    setHover([1, 0, 0, 0]);
                  }}
                  onMouseLeave={() => {
                    setHover([0, 0, 0, 0]);
                  }}
                >
                  <div className="col-2 d-flex justify-content-center">
                    {" "}
                    <img
                      style={{ width: "10vw", height: "7.5vw" }}
                      src={hover[0] ? icon8 : icon4}
                      alt=""
                    />
                  </div>
                  <div className="col-10">&nbsp;&nbsp;Resumen de compra</div>
                </div>
              </div>

              <div
                className="league-spartan-500 d-flex justify-content-center"
                style={{
                  color: "#818181",
                  marginTop: "9.17vw",
                  width: "100%",
                  height: "10vw",
                  fontSize: "5.56vw",
                }}
              >
                <div
                  className="d-flex justify-content-start admin-title"
                  style={{ width: "80%" }}
                  onClick={() => {
                    window.location.href = "/validar";
                  }}
                  onMouseEnter={() => {
                    setHover([0, 1, 0, 0]);
                  }}
                  onMouseLeave={() => {
                    setHover([0, 0, 0, 0]);
                  }}
                >
                  <div className="col-2 d-flex justify-content-center">
                    {" "}
                    <img
                      style={{ width: "3.89vw", height: "6.94vw" }}
                      src={hover[1] ? icon6 : icon2}
                      alt=""
                    />
                  </div>
                  <div className="col-10">&nbsp;&nbsp;Valida tu entrada</div>
                </div>
              </div>

              <div
                className="league-spartan-500 d-flex justify-content-center"
                style={{
                  color: "#818181",
                  marginTop: "9.17vw",
                  width: "100%",
                  height: "10vw",
                  fontSize: "5.56vw",
                }}
              >
                <div
                  className="d-flex justify-content-start admin-title"
                  style={{ width: "80%" }}
                  onClick={() => {
                    window.location.href = "/transferir";
                  }}
                  onMouseEnter={() => {
                    setHover([0, 0, 1, 0]);
                  }}
                  onMouseLeave={() => {
                    setHover([0, 0, 0, 0]);
                  }}
                >
                  <div className="col-2 d-flex justify-content-center">
                    {" "}
                    <img
                      style={{ width: "7.22vw", height: "8.33vw" }}
                      src={hover[2] ? icon5 : icon1}
                      alt=""
                    />
                  </div>
                  <div className="col-10">
                    &nbsp;&nbsp;Transfiere tu entrada
                  </div>
                </div>
              </div>

              <div
                className="league-spartan-500 d-flex justify-content-center"
                style={{
                  color: "#818181",
                  marginTop: "9.17vw",
                  width: "100%",
                  height: "10vw",
                  fontSize: "5.56vw",
                }}
              >
                <div
                  className="d-flex justify-content-start admin-title"
                  style={{ width: "80%" }}
                  onClick={() => {
                    window.location.href = "/recibir";
                  }}
                  onMouseEnter={() => {
                    setHover([0, 0, 0, 1]);
                  }}
                  onMouseLeave={() => {
                    setHover([0, 0, 0, 0]);
                  }}
                >
                  <div className="col-2 d-flex justify-content-center">
                    {" "}
                    <img
                      style={{ width: "9.17vw", height: "6.11vw" }}
                      src={hover[3] ? icon7 : icon3}
                      alt=""
                    />
                  </div>
                  <div className="col-11 d-flex">
                    &nbsp;&nbsp;Recibe tu transferencia
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (source === "naked") {
    return (
      <>
        <div
          className="col-12 league-spartan-500 d-sm-flex d-none align-items-center justify-content-center"
          style={{
            background: "linear-gradient(to right, #ff0043, #000000)",
            height: "5vw",
          }}
        >
          <div className="col-2"></div>
          <div className="col-2 d-flex align-items-center justify-content-center">
            <img
              src={headerLogo}
              alt="logo"
              style={{ height: "2.86vw", width: "12.08vw" }}
            ></img>
          </div>
          <div className="col-2"></div>
          <div className="col-1"></div>
          <div className="col-3 d-flex justify-content-center"></div>
          <div className="col-2"></div>
        </div>

        <div
          className="col-12 league-spartan-500 d-flex d-sm-none"
          style={{
            background: "linear-gradient(to right, #ff0043, #000000)",
            height: "13.33vw",
          }}
        >
          <div className="col-2 d-flex align-items-center justify-content-center">
            <img
              src={logosmall}
              style={{
                height: "8.61vw",
                width: "11.11vw",
                marginLeft: "4.17vw",
              }}
              alt=""
            ></img>
          </div>
          <div className="col-4 d-flex"></div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            {/* <button
              className="league-spartan-400"
              style={{
                color: "white",
                fontSize: "3.61vw",
                height: "5vw",
                maxWidth: "39.17vw",
                marginRight: "4.17vw",
                borderRadius: "100px",
                backgroundColor: "#ff0043",
                borderStyle: "none",
              }}
            >
              Administrar Entradas
            </button> */}
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

export const Loading = () => {
  const width = window.innerWidth;
  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={loadingLogo}
            alt=""
            style={{ width: "200px", height: "200px" }}
          ></img>
        </div>
      </div>
    </>
  );
};

export const Footer = (props) => {
  const { source } = props;
  if (source === "home") {
    return (
      <>
        <div
          className="col-12 d-sm-block d-none"
          style={{
            height: "23.33vw",
            background: "linear-gradient(to right, #ff0043, #000000)",
          }}
        >
          <div className="col-12" style={{ height: "3.07vw" }}></div>
          <div
            className="col-12 d-flex league-spartan-400"
            style={{ height: "1.72vw", fontSize: "2.34vw", color: "white" }}
          >
            <div className="col-3"></div>
            <div className="col-2" style={{ marginTop: "-12px" }}>
              Conócenos
            </div>
            <div className="col-3 text-center" style={{ marginTop: "-12px" }}>
              Contáctanos
            </div>
            <div className="col-3 text-end" style={{ marginTop: "-12px" }}>
              Libros de reclamaciones
            </div>
            <div className="col-1"></div>
          </div>
          <div className="col-12 d-flex " style={{ height: "1.09vw" }}></div>
          <div className="col-12 d-flex" style={{ height: "5.83vw" }}>
            <div className="col-1"></div>
            <div className="col-2">
              <img
                src={logofooter}
                alt=""
                style={{ height: "9.11vw", width: "10.42vw" }}
              ></img>
            </div>
            <div className="col-2 d-grid league-spartan-300">
              <a href="/politicas/acercadenosotros" className="a-custom">
                Acerca de nosotros
              </a>
              <a href="/politicas/preguntasfrecuentes" className="a-custom">
                Preguntas frecuentes
              </a>
              <a href="/politicas/terminosycondiciones" className="a-custom">
                Términos y condiciones
              </a>
              <a href="/politicas/privacidad" className="a-custom">
                Política de privacidad
              </a>
              <div></div>
            </div>
            <div className="col-1 d-flex justify-content-center">
              <img
                src={whatsapp}
                alt=""
                style={{ height: "4.69vw", width: "4.69vw" }}
              ></img>
            </div>
            <div className="col-1 d-flex justify-content-center">
              <img
                src={telegram}
                alt=""
                style={{ height: "4.69vw", width: "4.69vw" }}
              ></img>
            </div>
            <div className="col-1 d-flex justify-content-center">
              <img
                src={mail}
                alt=""
                style={{ height: "4.69vw", width: "4.69vw" }}
              ></img>
            </div>
            <div className="col-1"></div>
            <div className="col-2">
              <img
                src={reclamos}
                alt=""
                style={{ height: "5.21vw", width: "11.67vw" }}
              ></img>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="col-12 d-flex " style={{ height: "2.66vw" }}></div>
          <div className="col-12 d-flex " style={{ height: "5.94vw" }}>
            <div className="col-3"></div>
            <div className="col-8">
              <div
                className="col-12"
                style={{
                  borderRadius: "26px",
                  borderStyle: "solid",
                  borderColor: "white",
                  borderWidth: "5px",
                }}
              >
                <div
                  className="league-spartan-400 d-flex align-items-center"
                  style={{
                    fontSize: "3.33vw",
                    color: "white",
                    marginLeft: "4.17vw",
                  }}
                >
                  Siguenos
                  <img
                    src={facebook}
                    style={{ height: "4.17vw", marginLeft: "4.5vw" }}
                    alt=""
                  ></img>
                  <img
                    src={instagram}
                    style={{ height: "4.17vw", marginLeft: "4.5vw" }}
                    alt=""
                  ></img>
                  <img
                    src={linkedin}
                    style={{ height: "4.17vw", marginLeft: "4.5vw" }}
                    alt=""
                  ></img>
                  <img
                    src={twitter}
                    style={{ height: "4.17vw", marginLeft: "4.5vw" }}
                    alt=""
                  ></img>
                  <img
                    src={youtube}
                    style={{ height: "4.17vw", marginLeft: "4.5vw" }}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        <div
          className="col-12 d-sm-none d-grid"
          style={{
            background: "linear-gradient(to right, #ff0043, #000000)",
            height: "75.56vw",
          }}
        >
          <div
            className="col-12 d-flex"
            style={{ marginTop: "4.44vw", height: "7.78vw" }}
          >
            <div className="col-1"></div>
            <div className="col-5">
              <img src={headerLogo} alt="" style={{ height: "100%" }}></img>
            </div>
            <div className="col-6"></div>
          </div>
          {/* <div
            className="col-12 d-flex league-spartan-400"
            style={{
              marginTop: "2.22vw",
              height: "3.89vw",
              color: "white",
              fontSize: "5vw",
            }}
          >
            <div className="col-1"></div>
            <div className="col-3">Conócenos</div>
            <div className="col-8"></div>
          </div> */}
          <div
            className="col-12 d-flex league-spartan-400"
            style={{
              height: "3.33vw",
              color: "white",
              fontSize: "3.61vw",
              marginTop: "2.78vw",
            }}
          >
            <div className="col-1"></div>
            <div
              className="col-4  link-custom"
              onClick={() => {
                window.location.href = "/politicas/acercadenosotros";
              }}
            >
              · Acerca de nosotros
            </div>
            <div className="col-2"></div>
            <div
              className="col-5  link-custom"
              onClick={() => {
                window.location.href = "/politicas/preguntasfrecuentes";
              }}
            >
              · Preguntas frecuentes
            </div>
            <div className="col-0"></div>
          </div>
          <div
            className="col-12 d-flex league-spartan-400"
            style={{
              height: "3.33vw",
              color: "white",
              fontSize: "3.61vw",
              marginTop: "1.94vw",
            }}
          >
            <div className="col-1"></div>
            <div
              className="col-5 link-custom"
              onClick={() => {
                window.location.href = "/politicas/terminosycondiciones";
              }}
            >
              · Términos y condiciones
            </div>
            <div className="col-1"></div>
            <div
              className="col-5 link-custom"
              onClick={() => {
                window.location.href = "/politicas/privacidad";
              }}
            >
              · Política de privacidad
            </div>
            <div className="col-0"></div>
          </div>
          {/* <div
            className="col-12 d-flex league-spartan-400"
            style={{
              marginTop: "2.78vw",
              height: "3.89vw",
              color: "white",
              fontSize: "5vw",
            }}
          >
            <div className="col-1"></div>
            <div className="col-3">Contactanos</div>
            <div className="col-8"></div>
          </div> */}
          <div
            className="col-12 d-flex"
            style={{ height: "10.28vw", marginTop: "5.27vw" }}
          >
            <div className="col-2"></div>
            <div className="col-2 d-flex justify-content-end">
              <img src={whatsapp} alt="" style={{ height: "10.28vw" }}></img>
            </div>
            <div className="col-1"></div>
            <div className="col-2 d-flex justify-content-center">
              <img src={telegram} alt="" style={{ height: "10.28vw" }}></img>
            </div>
            <div className="col-1"></div>
            <div className="col-2 d-flex justify-content-start">
              <img src={mail} style={{ height: "10.28vw" }} alt=""></img>
            </div>
            <div className="col-2"></div>
          </div>

          {/* <div
            className="col-12 d-flex league-spartan-400"
            style={{
              fontSize: "5vw",
              marginTop: "3.06vw",
              color: "white",
              height: "4.44vw",
            }}
          >
            <div className="col-1"></div>
            <div className="col-3">Siguenos</div>
            <div className="col-8"></div>
          </div> */}
          <div
            className="col-12 d-flex"
            style={{ marginTop: "4.16vw", height: "10.56vw" }}
          >
            <div className="col-1"></div>
            <div className="col-2">
              <img src={facebook} style={{ height: "10.56vw" }} alt=""></img>
            </div>
            <div className="col-2">
              <img
                src={instagram}
                style={{ height: "10.56vw", marginLeft: "1.5vw" }}
                alt=""
              ></img>
            </div>
            <div className="col-2 d-flex justify-content-center">
              <img src={linkedin} style={{ height: "10.56vw" }} alt=""></img>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <img
                src={youtube}
                style={{ height: "10.56vw", marginRight: "1.5vw" }}
                alt=""
              ></img>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <img src={twitter} style={{ height: "10.56vw" }} alt=""></img>
            </div>
            <div className="col-1"></div>
          </div>
          <div
            className="col-12 d-flex league-spartan-400 align-items-center"
            style={{ marginTop: "3.33vw", fontSize: "5vw", color: "white" }}
          >
            <div className="col-1"></div>
            <div className="col-6">Libro de reclamaciones</div>
            <div className="col-5">
              {" "}
              <div
                className="col-12 d-flex justify-content-center"
                style={{ height: "14.44vw", marginBottom: "3.89vw" }}
              >
                <img src={reclamos} style={{ height: "11.67vw" }} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (source === "naked") {
    return (
      <>
        <div
          className="col-12 d-sm-flex d-none justify-content-center align-items-center"
          style={{
            height: "5.21vw",
            background: "linear-gradient(to right, #ff0043, #000000)",
          }}
        >
          <img src={logofooter} style={{ height: "3.43vw" }} alt=""></img>
        </div>

        <div
          className="col-12 d-sm-none d-flex justify-content-center align-items-center"
          style={{
            height: "7.22vw",
            background: "linear-gradient(to right, #ff0043, #000000)",
          }}
        >
          <img src={logofooter} style={{ height: "5.28vw" }} alt=""></img>
        </div>
      </>
    );
  }
  return <></>;
};
