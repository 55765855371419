import React, { useState, useEffect } from "react";
import { Header, Footer, Loading } from "../components/global/Components";
import {
  Carousel,
  Cine,
  Search,
  Chevere,
  EventCard,
} from "../components/home/Components";
import GetIP from "../components/IPServer";

const Home = () => {
  const [eventRecords, setEventRecords] = useState(null);
  const [bannerRecords, setBannerRecords] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const eventsResponse = await fetch(`${GetIP}?collection=events`);
      if (!eventsResponse.ok) {
        throw new Error("Failed to fetch events");
      }
      const eventData = await eventsResponse.json();
      setEventRecords(eventData);
      console.log(eventData);

      const bannerResponse = await fetch(`${GetIP}?collection=banner`);
      if (!bannerResponse.ok) {
        throw new Error("Failed to fetch banner");
      }
      const bannerData = await bannerResponse.json();
      setBannerRecords(bannerData);
      console.log(bannerData);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header source="home" />
          <Carousel bannerRecords={bannerRecords} />
          <Cine />
          <Search />
          <Chevere />
          <EventCard records={eventRecords} />
          <Footer source="home" />
        </>
      )}
    </>
  );
};

export default Home;
