import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Timer,
  StepsContent,
  StepsTitle,
} from "../components/checkout/Components";
import { Header, Footer, Loading } from "../components/global/Components";
import GetIP from "../components/IPServer";

function hex2str(hex) {
  hex = hex.toString(); //force conversion
  let str = "";
  for (var i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

function Checkout(props) {
  const { hexId } = useParams();
  const userId = hex2str(hexId);
  console.log(userId);
  const urlTime = null;

  const [userData, setUserData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let filter = encodeURIComponent(JSON.stringify({ _id: userId }));
        let response = await fetch(
          `${GetIP}?collection=users&filter=${filter}`
        );
        const p = (await response.json())[0];
        filter = encodeURIComponent(JSON.stringify({ _id: p.eventId }));
        response = await fetch(`${GetIP}?collection=events&filter=${filter}`);
        const q = (await response.json())[0];
        setUserData(p);
        setEventData(q);
        setLoading(false);
        console.log(p);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);
  if (loading)
    return (
      <>
        <Loading></Loading>
      </>
    );
  return (
    <>
      {/* Computer view */}
      <>
        <Header source="naked" />
        <Timer userData={userData} eventData={eventData} />
        {/* <StepsTitle userData={userData} eventData={eventData} /> */}
        <StepsContent userData={userData} eventData={eventData} />
        <Footer source="naked" />
      </>
    </>
  );
}

export default Checkout;
