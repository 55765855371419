import { PDFDownloadLink, Document, Page, View, Text, Image } from '@react-pdf/renderer'
const PdfDocument = (props) => {
    const { eventRecord, userRecord, checkoutCode } = props;
    return <>
        <Document>
            <Page>
                <View>

                    <View style={{
                        backgroundColor: "#FF0043",
                        height: "50px",
                        width: "100%",
                        justifyContent: "center",
                        display: "grid"
                    }}>
                        <Image src={"https://entradaspe.s3.us-east-2.amazonaws.com/assets/headerlogo.png?noCache=" + Math.random().toString()}
                            style={{
                                marginTop: "7px",
                                marginLeft: "200px",
                                height: "32px",
                                width: "170px",
                                justifyContent: "center"
                            }}
                        />
                    </View>
                    <View>
                        <Text style={{ width: "100%", marginTop: "20px", display: "grid", textAlign: "center" }}>
                            Pasos para realizar el pago
                        </Text>
                        <Text style={{ width: "100%", marginTop: "20px", display: "grid", textAlign: "start", marginLeft: "90px" }}>
                            Su monto a pagar es S/:  {userRecord.price}
                        </Text>
                        <Text style={{ width: "100%", marginTop: "20px", display: "grid", textAlign: "start", marginLeft: "90px" }}>
                            Su codigo de pago es:  {checkoutCode}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: 'row' }}>
                        <Image src={"https://entradaspe.s3.us-east-2.amazonaws.com/entradaspe/qrYape.jpg?noCache=" + Math.random().toString()}
                            style={{
                                marginTop: "45px",
                                marginLeft: "65px",
                                width: "45%",
                                height: "350px",
                                borderRadius: "20px",
                                display: "flex"
                            }}
                        />
                        <Image src={"https://entradaspe.s3.us-east-2.amazonaws.com/entradaspe/PASOS.png?noCache=" + Math.random().toString()}
                            style={{
                                marginTop: "20px",
                                marginLeft: "15px",
                                width: "45%",
                                height: "400px",
                                borderRadius: "20px",
                                display: "flex"
                            }}
                        />
                        <Text style={{ position: "relative", bottom: "-315px", left: "-120px", color: "#742284", fontSize: "20px" }}>{userRecord.price}</Text>
                        <Text style={{ position: "relative", bottom: "-360px", left: "-160px", color: "#000000", fontSize: "16px" }}>{checkoutCode}</Text>
                    </View>
                    <View>
                        <Text style={{ width: "100%", marginTop: "20px", display: "grid", textAlign: "center" }}>
                            Detalle de su compra
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Text style={{ width: "33%", marginTop: "20px", display: "grid", textAlign: "center" }}>
                            Tipo de entrada
                        </Text>
                        <Text style={{ width: "33%", marginTop: "20px", display: "grid", textAlign: "center" }}>
                            Cantidad
                        </Text>
                        <Text style={{ width: "33%", marginTop: "20px", display: "grid", textAlign: "center" }}>
                            Precio
                        </Text>
                    </View>
                    {userRecord.nTickets.map((_, i) => (
                        <View key={i} style={{ justifyContent: "center", display: "flex", flexDirection: "row", backgroundColor: `${i % 2 === 0 ? "#c5c5c5" : "#f0f0f0"}` }}>
                            <Text style={{ width: "33%", marginTop: "10px", marginBottom: "10px", display: "grid", textAlign: "center" }}>
                                {eventRecord.type[i]}
                            </Text>
                            <Text style={{ width: "33%", marginTop: "10px", marginBottom: "10px", display: "grid", textAlign: "center" }}>
                                {userRecord.nTickets[i]}
                            </Text>
                            <Text style={{ width: "33%", marginTop: "10px", marginBottom: "10px", display: "grid", textAlign: "center" }}>
                                S/. {eventRecord.price[i] * userRecord.nTickets[i]}
                            </Text>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    </>
};
export default PdfDocument;

