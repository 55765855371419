import React from "react";
import GetIP from "../components/IPServer";
import SmallLoading from "../components/SmallLoading";
import { createRoot } from 'react-dom/client'
let name = "";
function str2hex(str) {
    let hex = '';
    for (var i = 0; i < str.length; i++) {
        hex += '' + str.charCodeAt(i).toString(16);
    }
    return hex;
}
const Recibir = () => {
    const handleSubmit = async (event) => {
        console.log(name)
        event.preventDefault();
        document.getElementById('result').style = ""
        document.getElementById('gotoBtn').style = "display:none"
        createRoot(document.getElementById('result')).render(<SmallLoading></SmallLoading>)
        const transferCode = event.target[0].value
        let result = await fetch(`${GetIP}?transferCode=${transferCode}&options=recibirTicket&collectionName=tickets`)
        if (!result.ok) {
            document.getElementById('result').innerHTML = "El codigo ingresado no existe"
            return;
        }
        const ticketRecord = await result.json()
        result = await fetch(`${GetIP}?options=getone&collectionName=events&id=${ticketRecord.eventId}`)
        if (!result.ok) {
            document.getElementById('result').innerHTML = "El codigo ingresado no existe"
            return;
        }
        const eventRecord = await result.json()
        document.getElementById('result').style = "background:red"
        if (ticketRecord.used) {
            document.getElementById('result').innerHTML = "La entrada ya ha sido canjeada"
            return;
        }
        if (!ticketRecord.paid) {
            document.getElementById('result').innerHTML = "La entrada no ha sido pagada"
            return;
        }
        if (ticketRecord.dtTransferDone != "") {
            document.getElementById('result').innerHTML = "La entrada ya ha sido reclamada"
            return;
        }
        document.getElementById('result').style = "background:lime"
        document.getElementById('result').innerHTML = `El codigo ingresado es correcto<br>Evento: ${eventRecord.title}<br>Precio: S/ ${ticketRecord.ammount}<br>Zona: ${ticketRecord.type}`
        document.getElementById('gotoBtn').style = "display:grid"
        if (name == "goto") {
            let nTickets = new Array(eventRecord.type.length)
            for (let i = 0; i < nTickets.length; i++) {
                if (ticketRecord.type == eventRecord.type[i]) {
                    nTickets[i] = 1
                }
                else nTickets[i] = 0;
            }
            const dtNow = new Date();
            const data = {
                options: "recibirTicket",
                document: {
                    eventId: ticketRecord.eventId,
                    name: "",
                    dni: "",
                    cel: "",
                    confirmationCode: (Math.floor(Math.random() * 90000000) + 10000000).toString(),
                    valid: false,
                    dtGenerated: dtNow.getTime(),
                    nTickets: nTickets,
                    pricing: eventRecord.price,
                    price: ticketRecord.ammount,
                    checkoutCode: "",
                    paid: false,
                    dtPaid: "",
                    promised: "",
                    receivedFrom: ticketRecord._id,
                    bankAccount: ticketRecord.bankAccount,
                    dtEvent: new Date(eventRecord.datetime).getTime(),
                }
            }
            const response = await fetch(`${GetIP}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
            console.log(response)
            if (!response.ok) {
                alert("Intente nuevamente")
                return;
            }
            const jsonResponse = await response.json();
            console.log(jsonResponse)
            window.location.href = `/checkout/${str2hex(jsonResponse.insertedId + ',' + dtNow)}`;
        }
    }

    return <>
        <div className='container d-grid justify-content-center p-0' style={{ marginTop: '50px', marginBottom: '100px' }}>
            <div className='h4 text-center my-5'> Recibe tu transferencia ingresando el codigo correcto:</div>
            <div className='container py-2 d-grid' style={{ background: '#e5e5e5', borderRadius: '15px', maxWidth: '400px' }}>
                <form onSubmit={handleSubmit}>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Codigo de transferencia:</label>
                        <input title='Debe ingresar el numero de su entrada' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={0} maxLength={10}></input>
                    </div>
                    <div className='d-grid justify-content-center my-3'>
                        <div id='result' className="h6 text-center lh-lg"> </div>
                    </div>
                    <div className='d-grid justify-content-center my-3'>
                        <button className='btn btn-danger' onClick={() => { name = "" }}>Consultar</button>
                    </div>
                    <div id="gotoBtn" className='justify-content-center my-3' style={{ display: "none" }}>
                        <button className='btn btn-danger' onClick={() => { name = "goto" }}>Recibir transferencia</button>
                    </div>
                </form>
            </div>
        </div>
    </>
}
export default Recibir