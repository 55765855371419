import React, { useState, useEffect } from "react";
import "../../styles/bootstrap.min.css";
import maps from "../../assets/maps-preview.png";
import blackcalendar from "../../assets/blackcalendar.png";
import blackclock from "../../assets/blackclock.png";
import plus from "../../assets/plus.png";
import minus from "../../assets/minus.png";
import GetIP from "../IPServer";
import event_end from "../../assets/evento_finalizado.png";
import event_notstart from "../../assets/evento_noiniciado.png";

function str2hex(str) {
  let hex = "";
  for (var i = 0; i < str.length; i++) {
    hex += "" + str.charCodeAt(i).toString(16);
  }
  return hex;
}

export const UpperComponent = (props) => {
  const { eventData } = props;
  const [tickets, setTickets] = useState(eventData.type.map(() => 0));
  const [showTotal, setShowTotal] = useState(false);
  const [total, setTotal] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popup2Show, setPopup2Show] = useState(undefined);

  const Action = (action, idx) => {
    if (action === "plus") {
      if (tickets[idx] < eventData.max) {
        const updatedTickets = [...tickets];
        updatedTickets[idx]++;
        setTickets(updatedTickets);
      }
    }
    if (action === "minus") {
      if (tickets[idx] > 0) {
        const updatedTickets = [...tickets];
        updatedTickets[idx]--;
        setTickets(updatedTickets);
      }
    }
  };

  const Checkout = async () => {
    if (!showTotal) {
      alert("Debe seleccionar al menos un ticket");
      return;
    }
    let response = await fetch(
      `https://worldtimeapi.org/api/timezone/America/Lima`
    );
    const p = await response.json();

    if (p.datetime < eventData.datetimeStart) {
      setPopup2Show(event_notstart);
      setShowPopup(true);
      return;
    }

    if (p.datetime > eventData.datetime) {
      setPopup2Show(event_end);
      setShowPopup(true);
      return;
    }

    let data = {
      options: "newCheckout",
      document: {
        eventId: eventData._id,
        nTickets: tickets,
        pricing: eventData.price,
        price: total,
        dtGenerated: p.datetime,
        step: 1,
      },
    };
    response = await fetch(`${GetIP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const q = await response.json();
    if (!response.ok) {
      console.log(q);
      if (q.error === null) alert("Error del servidor");
      else alert(q.error);
      window.location.reload();
      return;
    }
    let hexId = str2hex(q.insertedId);
    window.location.href = `/checkout/${hexId}`;
  };

  useEffect(() => {
    const p = [...tickets];
    let r = 0;
    let q = 0;
    for (let i = 0; i < p.length; i++) {
      q += p[i] * eventData.price[i];
      r += p[i];
    }
    setTotal(q);
    setShowTotal(r > 0 ? true : false);
  }, [tickets, eventData.price]);

  const togglePopup = () => {
    setShowPopup(false);
  };
  return (
    <>
      {showPopup && (
        <div
          className="d-flex justify-content-center align-items-center"
          onClick={togglePopup}
          style={{
            position: "fixed",
            width: `100%`,
            height: `100%`,
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: "2",
            marginTop: "-5vw",
          }}
        >
          <img src={popup2Show} style={{ width: "40vw", height: "27vw" }}></img>
        </div>
      )}
      <div
        className="col-12 blur-bg"
        style={{
          background: `url("${eventData.image}")`,
        }}
      ></div>
      <div className="col-12 d-sm-flex align-items-center dark-opacity">
        <div className="col-12 d-sm-flex d-block">
          <div className="col-1"></div>
          <div className="col-sm-6 col-12 d-flex justify-content-center">
            <img className="image-event" src={eventData.image} alt=""></img>
          </div>
          <div className="col-1"></div>
          <div className="col-sm-3 col-12 ticket-small">
            <div className="tickets-pe">
              <div className="league-spartan-600 title">{eventData.title}</div>
              <div className="league-spartan-400 d-flex date">
                <div className="col-8 d-flex time">
                  <div>
                    <img
                      src={blackcalendar}
                      className="icon-height"
                      alt=""
                    ></img>
                  </div>
                  <div className="icon-padding">
                    &nbsp;&nbsp;{eventData.date}
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <div>
                    <img src={blackclock} className="icon-height" alt=""></img>
                  </div>
                  <div className="icon-padding">
                    &nbsp;&nbsp;{eventData.time}
                  </div>
                </div>
              </div>
              <div className="league-spartan-600 tickets-text">Entradas</div>
              {(() => {
                const elements = [];
                for (let i = 0; i < eventData.type.length; i++) {
                  let idx = i;
                  elements.push(
                    <>
                      <div className="col-12 grid-pe">
                        <div className="col-12 league-spartan-600 grid-type">
                          {eventData.type[idx]}
                        </div>
                        <div className="col-12 d-flex grid-margin">
                          <div className="col-8 league-spartan-600 grid-font-price">
                            S/{eventData.price[idx]}
                          </div>
                          <div className="col-4 d-flex league-spartan-600 justify-content-end grid-small-margin">
                            <div className="d-flex">
                              <img
                                className="btn-pe grid-qty-height"
                                src={minus}
                                onClick={() => {
                                  Action("minus", idx);
                                }}
                                alt=""
                              ></img>
                            </div>
                            <div className="text-center grid-qty">
                              {tickets[idx]}
                            </div>
                            <div className="d-flex">
                              <img
                                className="btn-pe grid-qty-height"
                                src={plus}
                                onClick={() => {
                                  Action("plus", idx);
                                }}
                                alt=""
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                return elements;
              })()}

              <div
                className="grid-total"
                style={{ display: showTotal ? "flex" : "none" }}
              >
                <div className="col-6 d-flex">
                  <span className="league-spartan-600">TOTAL</span>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <span className="league-spartan-500">
                    S/&nbsp;
                    {(() => {
                      return total.toFixed(2);
                    })()}
                  </span>
                </div>
              </div>

              <div className="col-12 d-flex grid-padding-ad">
                <div className="d-flex align-items-start">
                  <input type="checkbox" checked></input>
                </div>
                <div>
                  <div className="league-spartan-300 grid-ad-text">
                    Te informamos que tus datos, seran compartidos con el
                    organizador del evento, esto con la finalidad de tener mayor
                    seguridad.
                  </div>
                  <div className="league-spartan-400 grid-ad-text-2">
                    *Apto para publico general
                  </div>
                </div>
              </div>
              <div></div>
              <div className="grid-div-btn">
                <div
                  className="d-flex league-spartan-700 button-red justify-content-center grid-div-btn2"
                  onClick={() => {
                    Checkout();
                  }}
                >
                  Comprar Ahora
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const LowerComponent = (props) => {
  const { eventData } = props;
  return (
    <>
      <div className="col-12 d-sm-flex low-div">
        <div className="col-1"></div>
        <div className="col-sm-6 col-12 d-sm-block d-flex justify-content-center">
          <div className="col-sm-12 col-11 low-div-info">
            <div className="league-spartan-600 low-title-size">
              {eventData.title}
            </div>
            <div className="league-spartan-400 low-summary-size">
              {(() => {
                const elements = [];
                const q = eventData.summary.split("\n");
                for (let i = 0; i < q.length; i++) {
                  const idx = i;
                  elements.push(
                    <>
                      {q[idx]}
                      <br></br>
                    </>
                  );
                }
                return elements;
              })()}
            </div>
            <div className="league-spartan-600 low-info-size">
              Informacion Adicional
            </div>
            <div className="league-spartan-400 low-summary-size">
              {(() => {
                const elements = [];
                const q = eventData.info.split("\n");
                for (let i = 0; i < q.length; i++) {
                  const idx = i;
                  elements.push(
                    <>
                      {q[idx]}
                      <br></br>
                    </>
                  );
                }
                return elements;
              })()}
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div
          className="col-sm-3 low-map-main"
          style={{
            marginTop: (() => {
              if (eventData.type.length <= 3) return "0vw";
              return `${1.62 + 2.54 * (eventData.type.length - 4) + 1.2}vw`;
            })(),
          }}
        >
          <div className="low-map-city">
            <div className="league-spartan-600 low-city-font">Lima,PE</div>
            <div className="league-spartan-300 low-map-title">
              {eventData.title}
            </div>
          </div>
          <div className="low-map-image">
            <img
              src={maps}
              className="low-map-blur"
              alt=""
              onClick={() => {
                window.open(eventData.location_url);
              }}
            ></img>
          </div>
          <div className="d-flex league-spartan-500 low-organizer">
            <div className="d-flex align-items-center justify-content-center low-organizer2">
              <img
                src={eventData.organizer_image}
                className="low-organizer-image"
                alt=""
              ></img>
            </div>
            <div>
              <div className="low-organizer-text">Organiza</div>
              <div className="low-organizer-name">
                {eventData.organizer_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
