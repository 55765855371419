import React, { useState, useEffect } from "react";
import "../../styles/bootstrap.min.css";
import blackcalendar from "../../assets/blackcalendar.png";
import blackclock from "../../assets/blackclock.png";
import blacklocation from "../../assets/blacklocation.png";
import Loading from "../../components/Loading";
import redcheck from "../../assets/redcheck.png";
import yape from "../../assets/yape.png";
import plin from "../../assets/plin.png";
import GetIP from "../IPServer";

export const Timer = (props) => {
  const { userData, eventData } = props;
  const [ms, setMs] = useState(null);
  const [atStart, setAtStart] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [hours, setHours] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch(
          `https://worldtimeapi.org/api/timezone/America/Lima`
        );
        let p = await response.json();
        const q =
          15 * 60 * 1000 -
          (new Date(p.datetime) - new Date(userData.dtGenerated));
        setMs(q);
        setProgress((900000 - q) / 900000);
        setAtStart(performance.now());
        setHours(Math.floor(ms / (1000 * 60 * 60)));
        setMinutes(Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((ms % (1000 * 60)) / 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userData]);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = performance.now();
      const q = Math.floor(ms - (now - atStart));
      setProgress((900000 - q) / 900000);
      setHours(Math.floor(q / (1000 * 60 * 60)));
      setMinutes(Math.floor((q % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((q % (1000 * 60)) / 1000));
      if (q < 0) {
        clearInterval(interval);
        window.location.href = `/eventos/${eventData._id}`;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [loading]);

  if (loading) return <Loading />;

  return (
    <>
      {/* Computer view */}
      <>
        <div className="col-12 d-sm-block d-none">
          <div className="col-12 d-flex" style={{ height: "11.09vw" }}>
            <div className="col-1"></div>
            <div className="col-10 d-flex align-items-center justify-content-start">
              <div
                className="d-flex align-items-center"
                style={{ height: "5.21vw", width: "9.17vw" }}
              >
                <img src={eventData.image} style={{ width: "100%" }}></img>
              </div>
              <div
                className="d-grid align-items-center"
                style={{
                  height: "5.21vw",
                  width: "39.17vw",
                  marginLeft: "1vw",
                }}
              >
                <div
                  className="league-spartan-700"
                  style={{ fontSize: "1.72vw", whiteSpace: "nowrap" }}
                >
                  {eventData.title}
                </div>
                <div
                  className="league-spartan-400 d-flex align-items-center"
                  style={{ fontSize: "1.67vw" }}
                >
                  <img src={blackcalendar} style={{ height: "1.56vw" }}></img>
                  <div style={{ marginTop: "0.5vw" }}>
                    &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;
                  </div>
                  <img src={blackclock} style={{ height: "1.56vw" }}></img>
                  <div style={{ marginTop: "0.5vw" }}>
                    &nbsp; {eventData.time}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end w-50">
                <div className="d-block" style={{ width: "18.75vw" }}>
                  <div
                    className="league-spartan-600 d-flex justify-content-center"
                    style={{ fontSize: "3.02vw" }}
                  >
                    {hours.toString().padStart(2, "0")}:
                    {minutes.toString().padStart(2, "0")}:
                    {seconds.toString().padStart(2, "0")}
                  </div>
                  <div
                    className="league-spartan-400"
                    style={{ fontSize: "1.67vw", marginTop: "-1vw" }}
                  >
                    Tiempo restante de compra
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div
            className="col-12 d-flex"
            style={{ height: "0.47vw", backgroundColor: "#4c4747" }}
          >
            <div
              style={{
                height: "0.47vw",
                width: `${progress * 100}%`,
                backgroundColor: "#ff0043",
              }}
            ></div>
          </div>
        </div>

        <div className="col-12 d-block d-sm-none">
          <div className="col-12 d-flex" style={{ height: "17.22vw" }}>
            <div className="col-1"></div>
            <div className="col-10 d-flex align-items-center justify-content-start">
              <div
                className="d-flex align-items-center"
                style={{ height: "6.94vw" }}
              >
                <img src={eventData.image} style={{ height: "100%" }}></img>
              </div>
              <div
                className="d-grid align-items-center"
                style={{
                  height: "5.21vw",
                  width: "80.17vw",
                  marginLeft: "1vw",
                  marginTop: "-3.7vw",
                }}
              >
                <div
                  className="league-spartan-700"
                  style={{ fontSize: "3.61vw", whiteSpace: "nowrap" }}
                >
                  {eventData.title}
                </div>
                <div
                  className="league-spartan-400 d-flex align-items-center"
                  style={{ fontSize: "2.78vw", marginTop: "-1vw" }}
                >
                  <img src={blackcalendar} style={{ height: "1.56vw" }}></img>
                  <div style={{ marginTop: "0.5vw" }}>
                    &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;
                  </div>
                  <img src={blackclock} style={{ height: "1.56vw" }}></img>
                  <div style={{ marginTop: "0.5vw" }}>
                    &nbsp; {eventData.time}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end w-50">
                <div className="d-block" style={{ width: "18.75vw" }}>
                  <div
                    className="league-spartan-600 d-flex justify-content-center"
                    style={{ fontSize: "4.17vw" }}
                  >
                    {hours.toString().padStart(2, "0")}:
                    {minutes.toString().padStart(2, "0")}:
                    {seconds.toString().padStart(2, "0")}
                  </div>
                  <div
                    className="league-spartan-400 text-center"
                    style={{ fontSize: "1.94vw", marginTop: "-1vw" }}
                  >
                    Tiempo restante
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div
            className="col-12 d-flex"
            style={{ height: "0.56vw", backgroundColor: "#4c4747" }}
          >
            <div
              style={{
                height: "0.56vw",
                width: `${progress * 100}%`,
                backgroundColor: "#ff0043",
              }}
            ></div>
          </div>
        </div>
      </>
      {/* Mobile view */}
      <></>
    </>
  );
};
export const StepsTitle = (props) => {
  const { userData, eventData, step } = props;
  return (
    <>
      {/* Computer view */}
      <>
        <div className="col-12 d-sm-block d-none" style={{ height: "10.63vw" }}>
          <div className="col-12 d-flex">
            <div className="col-1"></div>
            <div
              className="col-11 league-spartan-400"
              style={{
                marginTop: "3.44vw",
                height: "1.51vw",
                fontSize: "1.93vw",
              }}
            >
              Paso {step} de 4
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-1"></div>
            <div
              className="col-11 league-spartan-600"
              style={{
                height: "2.14vw",
                fontSize: "2.29vw",
                marginTop: "1.51vw",
                marginBottom: "2.19vw",
              }}
            >
              {(() => {
                if (step === 1) return <>DATOS DE CONTACTO</>;
                if (step === 2) return <>CONFIRMACION DE CELULAR</>;
                if (step === 3) return <>INFORMACION DE PAGO</>;
                if (step === 4) return <>ENTRADAS RESERVADAS</>;
              })()}
            </div>
          </div>
        </div>
      </>
      {/* Mobile view */}
      <>
        <div className="col-12 d-sm-none d-block" style={{ height: "16.67vw" }}>
          <div className="col-12 d-flex">
            <div className="col-1"></div>
            <div
              className="col-11 league-spartan-400"
              style={{
                marginTop: "3.89vw",
                height: "2.78vw",
                fontSize: "4.17vw",
              }}
            >
              Paso {step} de 4
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-1"></div>
            <div
              className="col-11 league-spartan-600"
              style={{
                height: "2.78vw",
                fontSize: "4.174vw",
                marginTop: "3.33vw",
                marginBottom: "3.89vw",
              }}
            >
              {(() => {
                if (step === 1) return <>DATOS DE CONTACTO</>;
                if (step === 2) return <>CONFIRMACION DE CELULAR</>;
                if (step === 3) return <>INFORMACION DE PAGO</>;
                if (step === 4) return <>ENTRADAS RESERVADAS</>;
              })()}
            </div>
          </div>
        </div>
      </>
    </>
  );
};
let i = 0;
export const StepsContent = (props) => {
  const { userData, eventData } = props;
  console.log(userData);
  const [step, setStep] = useState(userData.step);
  const [name, setName] = useState(userData.name);
  const [number, setNumber] = useState(userData.number);
  const [dni, setDni] = useState(userData.dni);
  const [code, setCode] = useState(null);
  const [checkoutCode, setCheckoutCode] = useState(userData.checkoutCode);
  const [confirmationCode, setConfirmationcode] = useState(
    userData.confirmationCode
  );
  useEffect(() => {
    setStep(userData.step);
  }, [userData]);

  const OnEvent = (idx) => {
    if (idx === 2) {
      console.log(name, number, dni);
      if (name == null || number == null || dni == null) {
        const q = document.getElementById("nombre").value;
        const p = document.getElementById("celular").value;
        const r = document.getElementById("dni").value;

        if (q === null || q.length < 3) {
          alert("Debe ingresar un nombre");
          return;
        }
        if (p === null || p.length != 9) {
          alert("Debe ingresar un numero telefonico valido");
          return;
        }
        if (r === null || r.length != 8) {
          alert("Debe ingresar un DNI valido");
          return;
        }
        setName(document.getElementById("nombre").value);
        setNumber(document.getElementById("celular").value);
        setDni(document.getElementById("dni").value);
      }
    }
    if (idx === 3) {
      setCode(document.getElementById("confirmationCode").value);
    }
    i = idx;
    setStep(idx);
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log(step);
      try {
        if (step === 1) {
          document.getElementById("nombre").value = name ? name : "";
          document.getElementById("celular").value = number ? number : "";
          document.getElementById("dni").value = dni ? dni : "";
        }
        if (step === 2) {
          if (userData.step === 2) return;
          console.log("entra a step2");
          const r = userData.confirmationCode
            ? userData.confirmationCode
            : (Math.floor(Math.random() * 900000) + 100000).toString();
          setConfirmationcode(r);
          let data = {
            options: "step-1",
            id: userData._id,
            document: {
              name: name,
              cel: number,
              dni: dni,
              step: 2,
              confirmationCode: r,
            },
          };
          let response = await fetch(`${GetIP}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const q = await response.json();
          if (!response.ok) {
            console.log(q);
            alert("Error del servidor");
            return;
          }
        }
        if (step === 3) {
          if (userData.step == 3) return;
          if (code != confirmationCode) {
            alert(
              "El codigo no es correcto, si no ha recibido el codigo o ha ingresado mal su celular realice nuevamente la compra"
            );
            setStep(2);
            return;
          }
          let data = {
            options: "step-2",
            id: userData._id,
            document: {
              step: step,
              free: eventData.free,
            },
          };
          let response = await fetch(`${GetIP}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const q = await response.json();
          if (!response.ok) {
            console.log(q);
            alert("Error del servidor");
            return;
          }
          const filter = encodeURIComponent(
            JSON.stringify({ _id: userData._id })
          );
          response = await fetch(`${GetIP}?collection=users&filter=${filter}`);
          const p = (await response.json())[0];
          console.log(p);
          setCheckoutCode(p.checkoutCode);
          if (eventData.free) setStep(4);
        }
        if (step === 4) {
          if (userData.step === 4) return;
          alert(
            "Se le enviara un mensaje con la informacion de pago a su numero de telefono"
          );
          let data = {
            options: "step-3",
            id: userData._id,
          };
          let response = await fetch(`${GetIP}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const q = await response.json();
          if (!response.ok) {
            console.log(q);
            alert("Error del servidor");
            return;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [step]);
  return (
    <>
      <StepsTitle userData={userData} eventData={eventData} step={step} />
      {/* Computer view */}
      <>
        {/* Step 1 Computer view */}
        <div className="d-flex">
          {(() => {
            if (step === 1)
              return (
                <>
                  <div className="col-12 d-sm-flex d-block pe">
                    {/* User Data */}
                    <div className="col-1"></div>
                    <div className="col-sm-5 col-11">
                      <div className="d-grid align-items-center a-pe">
                        <div className="col-12 d-flex justify-content-center league-spartan-600">
                          <div className="col-sm-8 col-10">
                            <div>
                              <div className="b-pe">Nombre completo</div>
                            </div>
                            <div>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  id="nombre"
                                  className="form-control c-pe"
                                  placeholder=""
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  onInput={() => {
                                    var inputField =
                                      document.getElementById("nombre");
                                    var inputValue = inputField.value;
                                    var regex =
                                      /^[A-Za-zÁáÉéÍíÓóÚúÜüÑñ\s]{0,32}$/i;
                                    if (!regex.test(inputValue)) {
                                      inputField.value = inputValue.slice(
                                        0,
                                        -1
                                      );
                                    }
                                  }}
                                ></input>
                              </div>
                            </div>

                            <div>
                              <div className="b-pe d-pe">Celular</div>
                            </div>
                            <div>
                              <div>
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text c-pe"
                                      id="basic-addon1"
                                    >
                                      +51
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    id="celular"
                                    className="form-control c-pe"
                                    placeholder=""
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onInput={() => {
                                      var inputField =
                                        document.getElementById("celular");
                                      var inputValue = inputField.value;
                                      var regex = /^(?:\b[0-9]{1,9}\b)?$/;
                                      if (!regex.test(inputValue)) {
                                        inputField.value = inputValue.slice(
                                          0,
                                          -1
                                        );
                                      }
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="b-pe d-pe">DNI</div>
                            </div>
                            <div>
                              <div>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    id="dni"
                                    className="form-control c-pe"
                                    placeholder=""
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onInput={() => {
                                      var inputField =
                                        document.getElementById("dni");
                                      var inputValue = inputField.value;
                                      var regex = /^(?:\b[0-9]{1,8}\b)?$/;
                                      if (!regex.test(inputValue)) {
                                        inputField.value = inputValue.slice(
                                          0,
                                          -1
                                        );
                                      }
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex league-spartan-500 justify-content-center e-pe">
                          <button
                            type="button"
                            onClick={() => {
                              OnEvent(2);
                            }}
                            className="button-custom f-pe"
                          >
                            <div>Continuar</div>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Event Data */}
                    <div className="col-sm-5 col-11 d-sm-flex justify-content-end">
                      <div className="d-sm-grid d-block align-content-center g-pe">
                        <div className="d-flex league-spartan-600 h-pe">
                          {eventData.title}
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blackcalendar}></img>
                          <div className="k-pe">
                            &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <img className="j-pe" src={blackclock}></img>
                          <div className="k-pe">&nbsp;{eventData.time}</div>
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blacklocation}></img>
                          <div style={{ marginTop: "0.3vw" }}>
                            &nbsp;{eventData.place}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                        <div className="d-flex league-spartan-600 i-pe">
                          Mi compra
                        </div>
                        <div className="col-12 l-pe">
                          {(() => {
                            const elements = [];
                            for (let i = 0; i < userData.nTickets.length; i++) {
                              elements.push(
                                <>
                                  <div
                                    key={i}
                                    className="d-flex col-12"
                                    style={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <div className="col-8 m-pe">
                                      <div className="d-flex justify-content-start league-spartan-600">
                                        {userData.nTickets[i]}&nbsp;×&nbsp;
                                        {eventData.type[i]}
                                      </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end n-pe">
                                      <div className="league-spartan-400">
                                        S/.&nbsp;
                                        {userData.nTickets[i] *
                                          userData.pricing[i]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            return elements;
                          })()}
                        </div>

                        <div className="col-12 league-spartan-600 d-flex o-pe">
                          <div className="col-4">Total</div>
                          <div className="col-8 d-flex justify-content-end">
                            S/.&nbsp;
                            {(() => {
                              let total = 0;
                              for (
                                let i = 0;
                                i < userData.nTickets.length;
                                i++
                              ) {
                                total =
                                  total +
                                  userData.nTickets[i] * userData.pricing[i];
                              }
                              return total;
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </>
              );
          })()}
        </div>

        {/* Step 2 Computer view*/}
        <div className="d-flex">
          {(() => {
            if (step === 2)
              return (
                <>
                  <div className="col-12 d-sm-flex d-block pe">
                    {/* User Data */}
                    <div className="col-1"></div>
                    <div className="col-sm-5 col-11">
                      <div className="d-grid align-items-center a-pe">
                        <div className="col-12 d-flex justify-content-center league-spartan-600">
                          <div className="col-8 text-center">
                            <div>
                              <div className="b-pe">Codigo de confirmacion</div>
                            </div>
                            <div>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  id="confirmationCode"
                                  className="form-control c-pe"
                                  placeholder=""
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  onInput={() => {
                                    var inputField =
                                      document.getElementById(
                                        "confirmationCode"
                                      );
                                    var inputValue = inputField.value;
                                    var regex = /^(?:\b[0-9]{1,6}\b)?$/;
                                    if (!regex.test(inputValue)) {
                                      inputField.value = inputValue.slice(
                                        0,
                                        -1
                                      );
                                    }
                                  }}
                                ></input>
                              </div>
                            </div>
                            <span className="league-spartan-400">
                              Un codigo de confirmacion le llego a su celular{" "}
                              {number}
                              <br></br>
                              <br></br>
                              Debe ingresarlo en el campo de arriba.
                              <br></br>
                            </span>
                          </div>
                        </div>

                        <div className="col-12 d-flex league-spartan-500 justify-content-center e-pe">
                          <button
                            type="button"
                            onClick={() => {
                              OnEvent(3);
                            }}
                            className="button-custom f-pe"
                          >
                            <div>Continuar</div>
                          </button>
                        </div>

                        <div className="col-12 d-none  league-spartan-500 justify-content-center e-pe">
                          <button
                            type="button"
                            onClick={() => {
                              OnEvent(1);
                              OnEvent(2);
                            }}
                            className="button-custom f-pe"
                          >
                            <div>Reenviar</div>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Event Data */}
                    <div className="col-sm-5 col-11 d-sm-flex justify-content-end">
                      <div className="d-sm-grid d-block align-content-center g-pe">
                        <div className="d-flex league-spartan-600 h-pe">
                          {eventData.title}
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blackcalendar}></img>
                          <div className="k-pe">
                            &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <img className="j-pe" src={blackclock}></img>
                          <div className="k-pe">&nbsp;{eventData.time}</div>
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blacklocation}></img>
                          <div style={{ marginTop: "0.3vw" }}>
                            &nbsp;{eventData.place}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                        <div className="d-flex league-spartan-600 i-pe">
                          Mi compra
                        </div>
                        <div className="col-12 l-pe">
                          {(() => {
                            const elements = [];
                            for (let i = 0; i < userData.nTickets.length; i++) {
                              elements.push(
                                <>
                                  <div
                                    key={i}
                                    className="d-flex col-12"
                                    style={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <div className="col-8 m-pe">
                                      <div className="d-flex justify-content-start league-spartan-600">
                                        {userData.nTickets[i]}&nbsp;×&nbsp;
                                        {eventData.type[i]}
                                      </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end n-pe">
                                      <div className="league-spartan-400">
                                        S/.&nbsp;
                                        {userData.nTickets[i] *
                                          userData.pricing[i]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            return elements;
                          })()}
                        </div>

                        <div className="col-12 league-spartan-600 d-flex o-pe">
                          <div className="col-4">Total</div>
                          <div className="col-8 d-flex justify-content-end">
                            S/.&nbsp;
                            {(() => {
                              let total = 0;
                              for (
                                let i = 0;
                                i < userData.nTickets.length;
                                i++
                              ) {
                                total =
                                  total +
                                  userData.nTickets[i] * userData.pricing[i];
                              }
                              return total;
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </>
              );
          })()}
        </div>

        {/* Step 3 Computer view*/}
        <div className="d-flex">
          {(() => {
            if (step === 3)
              return (
                <>
                  <div className="col-12 d-sm-flex d-block pe">
                    {/* User Data */}
                    <div className="col-1"></div>
                    <div className="col-sm-5 col-11">
                      <div className="a-pe">
                        <div className="col-12 d-flex league-spartan-600 align-items-center p-pe">
                          <img className="q-pe" src={redcheck}></img>
                          <div className="r-pe">BILLETERA ELECTRÓNICA</div>
                          <img className="s-pe" src={yape}></img>
                          <img className="t-pe" src={plin}></img>
                        </div>

                        <div className="u-pe"></div>

                        <div className="col-12 d-flex league-spartan-500 justify-content-center">
                          <div className="d-flex justify-content-center align-items-center v-pe">
                            <div className="w-pe">
                              Número de Yape o Plin: 995 396 866
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex league-spartan-500 justify-content-center">
                          <div className="d-flex justify-content-center align-items-center v-pe x-pe">
                            <div style={{ marginTop: "0.25vw" }}>
                              Nombre de cuenta: Karla Adriana Alvarez Rojas
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex league-spartan-400 justify-content-center">
                          <div className="d-flex justify-content-center align-items-center y-pe">
                            <div style={{ marginTop: "0.25vw" }}>
                              1. Ingresa a Yape o Plin.<br></br> 2. Coloca el
                              Número 995 396 866.<br></br> 3. Ingresa el monto a
                              pagar.
                              <br></br> 4.{" "}
                              <span style={{ color: "#ff0043" }}>
                                MUY IMPORTANTE
                              </span>
                              , agregar mensaje indicando el codigo de pago.
                              <br></br>
                              5. Se le enviara un mensaje con la informacion de
                              pago a su numero de telefono
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex league-spartan-500 justify-content-center">
                          <div className="d-flex justify-content-center align-items-center v-pe z-pe">
                            <div style={{ marginTop: "0.25vw" }}>
                              Su codigo de compra es: {checkoutCode}
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-12 d-flex league-spartan-500 justify-content-center"
                          style={{ marginTop: "1.3vw" }}
                        >
                          <button
                            style={{ display: checkoutCode ? "block" : "none" }}
                            onClick={() => {
                              OnEvent(4);
                            }}
                            className="button-custom f-pe"
                          >
                            <div>Continuar</div>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Event Data */}
                    <div className="col-sm-5 col-11 d-sm-flex justify-content-end">
                      <div className="d-sm-grid d-block align-content-center g-pe">
                        <div className="d-flex league-spartan-600 h-pe">
                          {eventData.title}
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blackcalendar}></img>
                          <div className="k-pe">
                            &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <img className="j-pe" src={blackclock}></img>
                          <div className="k-pe">&nbsp;{eventData.time}</div>
                        </div>
                        <div className="d-flex align-items-center league-spartan-400 i-pe">
                          <img className="j-pe" src={blacklocation}></img>
                          <div style={{ marginTop: "0.3vw" }}>
                            &nbsp;{eventData.place}&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                        <div className="d-flex league-spartan-600 i-pe">
                          Mi compra
                        </div>
                        <div className="col-12 l-pe">
                          {(() => {
                            const elements = [];
                            for (let i = 0; i < userData.nTickets.length; i++) {
                              elements.push(
                                <>
                                  <div
                                    key={i}
                                    className="d-flex col-12"
                                    style={{ backgroundColor: "#f4f4f4" }}
                                  >
                                    <div className="col-8 m-pe">
                                      <div className="d-flex justify-content-start league-spartan-600">
                                        {userData.nTickets[i]}&nbsp;×&nbsp;
                                        {eventData.type[i]}
                                      </div>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end n-pe">
                                      <div className="league-spartan-400">
                                        S/.&nbsp;
                                        {userData.nTickets[i] *
                                          userData.pricing[i]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            return elements;
                          })()}
                        </div>

                        <div className="col-12 league-spartan-600 d-flex o-pe">
                          <div className="col-4">Total</div>
                          <div className="col-8 d-flex justify-content-end">
                            S/.&nbsp;
                            {(() => {
                              let total = 0;
                              for (
                                let i = 0;
                                i < userData.nTickets.length;
                                i++
                              ) {
                                total =
                                  total +
                                  userData.nTickets[i] * userData.pricing[i];
                              }
                              return total;
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </>
              );
          })()}
        </div>

        {/* Step 4 Computer view*/}
        {(() => {
          if (step === 4)
            return (
              <>
                <div className="col-12 d-sm-flex d-block pe">
                  {/* User Data */}
                  <div className="col-1"></div>
                  <div className="col-sm-5 col-11 d-flex align-items-center">
                    <div className="a-pe">
                      <div className="col-12 d-flex league-spartan-500 align-items-center aa-pe">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="col-10 text-center ab-pe">
                            Sus entradas han sido reservadas. <br></br>
                            <br></br>
                            <span>
                              Tiene un plazo de 24 horas para realizar el pago,
                              de lo contrario sus entradas serán anuladas.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 d-flex league-spartan-500 justify-content-center"
                        style={{ marginTop: "3.3vw" }}
                      >
                        <button
                          onClick={() => {
                            window.location.href = "/";
                          }}
                          className="button-custom f-pe"
                        >
                          <div>Salir</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Event Data */}
                  <div className="col-sm-5 col-11 d-sm-flex justify-content-end">
                    <div className="d-sm-grid d-block align-content-center g-pe">
                      <div className="d-flex league-spartan-600 h-pe">
                        {eventData.title}
                      </div>
                      <div className="d-flex align-items-center league-spartan-400 i-pe">
                        <img className="j-pe" src={blackcalendar}></img>
                        <div className="k-pe">
                          &nbsp;{eventData.date}&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <img className="j-pe" src={blackclock}></img>
                        <div className="k-pe">&nbsp;{eventData.time}</div>
                      </div>
                      <div className="d-flex align-items-center league-spartan-400 i-pe">
                        <img className="j-pe" src={blacklocation}></img>
                        <div style={{ marginTop: "0.3vw" }}>
                          &nbsp;{eventData.place}&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      </div>
                      <div className="d-flex league-spartan-600 i-pe">
                        Mi compra
                      </div>
                      <div className="col-12 l-pe">
                        {(() => {
                          const elements = [];
                          for (let i = 0; i < userData.nTickets.length; i++) {
                            elements.push(
                              <>
                                <div
                                  key={i}
                                  className="d-flex col-12"
                                  style={{ backgroundColor: "#f4f4f4" }}
                                >
                                  <div className="col-8 m-pe">
                                    <div className="d-flex justify-content-start league-spartan-600">
                                      {userData.nTickets[i]}&nbsp;×&nbsp;
                                      {eventData.type[i]}
                                    </div>
                                  </div>
                                  <div className="col-4 d-flex justify-content-end n-pe">
                                    <div className="league-spartan-400">
                                      S/.&nbsp;
                                      {userData.nTickets[i] *
                                        userData.pricing[i]}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }

                          return elements;
                        })()}
                      </div>

                      <div className="col-12 league-spartan-600 d-flex o-pe">
                        <div className="col-4">Total</div>
                        <div className="col-8 d-flex justify-content-end">
                          S/.&nbsp;
                          {(() => {
                            let total = 0;
                            for (let i = 0; i < userData.nTickets.length; i++) {
                              total =
                                total +
                                userData.nTickets[i] * userData.pricing[i];
                            }
                            return total;
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </>
            );
        })()}
      </>
      <></>
    </>
  );
};
