import React from "react";
import { FidgetSpinner } from "react-loader-spinner";

const SmallLoading = () => {
    return <>
        <div className='d-grid justify-content-center align-items-center' style={{ height: "100px" }}>
            <FidgetSpinner
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                backgroundColor='red'
            />
        </div>
    </>
}

export default SmallLoading;