import React from "react";
import "../../styles/bootstrap.min.css";
import terminosycondiciones from "../../assets/terminosycondiciones.png";
import acercadenosotros from "../../assets/acercadenosotros.png";
import preguntasfrecuentes from "../../assets/preguntasfrecuentes.png";
export const Policy = (props) => {
  const { source } = props;
  if (source === "terminosycondiciones") {
    return (
      <>
        <div className="col-12 d-flex">
          <img
            src={terminosycondiciones}
            alt=""
            style={{ width: "100%" }}
          ></img>
        </div>
        <div
          className="col-12 d-flex justify-content-center"
          style={{ backgroundColor: "#d9d9d9" }}
        >
          <div className="col-sm-10 col-12 d-flex league-spartan-400 po-a">
            Cualquier persona que no actepte estos términos y condiciones
            generales y las políticas de privacidad, los cuales tienen un
            carácter obligatorio y vinculante, deberá abstenerse de utilizar el
            PORTAL y/o los servicios.
            <br />
            <br />
            CAPACIDAD LEGAL
            <br />
            <br />
            Los eventos y/o actividades promocionadas a través de Entradas Pe
            sólo están disponibles para personas que tengan capacidad legal para
            contratar. No podrán utilizar los servicios del PORTAL las personas
            que no tengan esa capacidad entre estos los menores de edad. Los
            actos que los menores realicen en este sitio serán responsabilidad
            de sus padres, tutores, encargados o curadores, y por tanto se
            considerarán realizados por éstos en ejercicio de la representación
            legal con la que cuentan. Quien registre un USUARIO como empresa,
            deberá tener capacidad para contratar a nombre de tal persona
            jurídica y de obligar a la misma en los términos de este Acuerdo.
            <br />
            <br />
            REGISTRO Y USO DEL PORTAL
            <br />
            <br />
            Para acceder a las promociones y adquisición de entradas a los
            eventos ofrecidos en el PORTAL, el USUARIO deberá completar el
            formulario de registro con su información personal de manera exacta,
            precisa y verdadera («Datos Personales»), siendo que el USUARIO
            asume el compromiso de actualizar los Datos Personales conforme
            resulte necesario. ENTRADA YA podrá utilizar diversos medios para
            identificar a sus miembros, entre ellos el USUARIO, pero ENTRADA YA
            no se responsabiliza por la certeza de los Datos Personales
            provistos por éste. El USUARIO garantiza y responde, en cualquier
            caso, de la exactitud, veracidad, vigencia y autenticidad de los
            Datos Personales ingresados. Cada miembro sólo podrá ser titular de
            1 (una) cuenta en el PORTAL de ENTRADA YA, no pudiendo acceder a más
            de 1 (una) cuenta en el PORTAL con distintas direcciones de correo
            electrónico o falseando, modificando y/o alterando sus datos
            personales de cualquier manera posible. Si se verificara o
            sospechara un uso fraudulento y/o malintencionado y/o contrario a
            estos Términos y Condiciones y/o contrarios a la buena fe, ENTRADA
            YA tendrá el derecho inapelable de suspender o inhabilitar
            permanentemente el acceso del USUARIO al PORTAL, así como podrá dar
            de baja la compra efectuada, sin que ello genere derecho alguno a
            resarcimiento, pago, devolución y/o indemnización al USUARIO.
            <br />
            <br />
            El USUARIO una vez registrado, dispondrá de su dirección de email y
            una clave secreta (en adelante, CLAVE) que le permitirá el acceso
            personalizado, confidencial y seguro. En caso de poseer estos datos,
            el USUARIO tendrá la posibilidad de cambiar la CLAVE de acceso para
            lo cual deberá sujetarse al procedimiento establecido en el sitio
            respectivo. El USUARIO se obliga a mantener la confidencialidad de
            su Clave de acceso, asumiendo totalmente la responsabilidad por el
            mantenimiento de la confidencialidad de su clave secreta registrada
            en este sitio web, la cual le permite efectuar compras y obtener
            información sobre la cuenta que el USUARIO haya generado en el
            PORTAL. Dicha clave es de uso personal, y su entrega a terceros no
            involucra responsabilidad de ENTRADA YA en caso de utilización
            indebida, negligente y/o incorrecta.
            <br />
            <br />
            Asimismo, el USUARIO autoriza a ENTRADA YA a enviarle boletines y
            otras comunicaciones, ya sean promocionales o no. El USUARIO podrá
            en cualquier momento restringir dicha autorización comunicando dicha
            intención al correo electrónico [info@entradaya.pe]
            <br />
            <br />
            CONDICIONES SOBRE LA REALIZACION DE EVENTOS O ESPECTÁCULOS
            <br />
            <br />
            El USUARIO declara conocer qué ENTRADA YA opera exclusivamente como
            un punto de venta de entradas para asistir a eventos o espectáculos.
            En ese sentido, la realización de cualquier evento o espectáculo
            promocionado a través del PORTAL, no depende de ENTRADA YA, esta
            responsabilidad es del empresario o realizador del evento o
            espectáculo (en adelante, ORGANIZADOR) cuyo nombre e identificación
            aparecen en la entrada y de él dependen los horarios, condiciones de
            seguridad, ubicaciones, realización, organización o contenido del
            evento.
            <br />
            <br />
            3.2 ENTRADA YA no es responsable por el aplazamiento o cancelación
            del evento, así como por cualquier hecho que ocurra antes, durante o
            con posterioridad a la celebración del mismo.
            <br />
            <br />
            3.3 El USUARIO tiene pleno conocimiento que la fecha, hora o venue
            del evento es susceptible de ser modificada por el ORGANIZADOR,
            siendo que en tal escenario la entrada adquirida por el USUARIO a
            través del PORTAL de ENTRADA YA será válida para ser utilizada en la
            nueva fecha y hora que fije el ORGANIZADOR.
            <br />
            <br />
            En caso de cancelación y devolución del dinero de sus entradas
            adquiridas, el costo administrativo y bancario es del 10 %, que será
            descontado del total pagado.
            <br />
            <br />
            3.4 El día del evento o espectáculo, al ingresar al establecimiento,
            el público podrá estar sujeto a verificaciones adicionales por
            razones de seguridad, de acuerdo a lo establecido por el
            Organizador. El USUARIO faculta al ORGANIZADOR que ante la negativa
            a cumplir con alguna de las verificaciones requeridas, no se
            permitirá el ingreso al evento de las personas portadoras de la
            entrada adquirida mediante el PORTAL.
            <br />
             3.4.1 En caso de eventos de programas grabados en vivo, el
            ORGANIZADOR tiene la potestad de acomodar en las zonas que crea
            necesario a usuarios de zonas de cortesia u otras zonas para el bien
            del programa en vivo y la grabacion del mismo.
            <br />
            3.5 El ORGANIZADOR podrá reservarse el derecho de prohibirle al
            público filmar o grabar este evento, así como restringir el ingreso
            de grabadoras, filmadoras, máquinas fotográficas, latas, botellas,
            armas o cualquier objeto que el ORGANIZADOR considere peligroso.
            <br />
            EL ORGANIZADOR, podra tambien reservarse el derecho de prohibir el
            ingreso a personas en estado de ebriedad o estando en consumo de
            sustancias ilicitas, por seguridad de la misma persona, y los
            asistentes.
            <br />
            <br />
            3.6 En caso de cancelación del evento por causas atribuibles al
            ORGANIZADOR, dicho ORGANIZADOR será el único responsable de
            reintegrar el dinero correspondiente, mas no los gastos de envío y
            uso del sistema ENTRADA YA, en cuyo caso se indicará el
            procedimiento a seguir en el PORTAL conforme a las pautas que brinde
            el ORGANIZADOR.
            <br />
            <br />
            3.7 Si la cancelación del evento se realizara por causas no
            atribuibles a el ORGANIZADOR, no se realizarán reembolsos, siendo
            que el USUARIO desde ya acepta que se le otorgue a cambio una o más
            entradas para otros eventos promocionados por el ORGANIZADOR, las
            cuales tendrán el mismo o similar valor a las anteriormente
            adquiridas por el USUARIO a través del PORTAL. Si existiera alguna
            diferencia en monto dinerario ésta podrá ser reintegrada al USUARIO
            conforme a las indicaciones que brinde el ORGANIZADOR en un plazo de
            120 días hábiles contados desde la fecha de la cancelación del
            evento.
            <br />
            <br />
            3.7.1 La devolución del dinero para usuarios, solo dará efecto si el
            evento es cancelado en su totalidad, ENTRADA YA no ofrece reembolsos
            ni cambios de fecha a ningún usuario, por ninguna circunstancia.
            <br />
            <br />
            <br />
            3.7.2 Para eventos para adultos: Los niños, menores de 12 años,
            seran ubicados en el lugar que produccion elija dentro de la zona
            adquirida, por temas de seguridad del evento.
            <br />
            <br />
            3.7.3 Los menores de 18 años siempre tendran que ingresar al evento
            acompañado de un adulto responsable, queda totalemente prohibido el
            ingreso de menores de edad sin supervicion adulta.
            <br />
            <br />
            3.7.4 Entrada Ya no se responsabiliza por la perdida de articulos
            personales dentro de cualquiera de los venues donde se realicen los
            eventos adquiridos.
            <br />
            <br />
            3.7.5 Para entradas con Meet & Greet que no incluyan entradas, EL
            CLIENTE para poder acceder a esta entrada, debera comprar una
            entrada para el espectáculo, sin entrada al show no podra acceder al
            Meet & Greet, sin opción a reclamo.
            <br />
            <br />
            3.8 CONDICIONES SOBRE LA COMPRA Y MEDIOS DE PAGO
            <br />
            <br />
            El PORTAL de ENTRADA YA cuenta con un certificado SSL (Secure Socket
            Lay) que permite cifrar la información bancaria de nuestros clientes
            para mayor seguridad, integridad del sistema y de la información.
            <br />
            <br />
            Las compras realizadas en el PORTAL con tarjetas de débito o
            crédito, están sujetas a la verificación de los datos y aceptación
            de la transacción por parte de la entidad financiera emisora de la
            tarjeta.
            <br />
            <br />
            El USUARIO acepta que los datos de evento, número de entradas,
            ubicaciones, valor de las entradas, fechas y horas del evento, han
            sido revisados por él y la información ingresada al PORTAL es de su
            responsabilidad. ENTRADA YA, no está obligada a hacer devoluciones
            de dinero por errores de fechas, horas, nombre de eventos, calidad
            de los espectáculos, o en general causas ajenas a responsabilidades
            propias o de sus agentes. ENTRADA YA tampoco está obligada a reponer
            entradas perdidas, deterioradas, rotas o robadas, estas son de
            entera responsabilidad del USUARIO.
            <br />
            <br />
            Las entradas compradas a través del PORTAL que requieran ser
            entregadas por delivery a solicitud del USUARIO tendrán un recargo
            adicional dependiendo del punto de entrega, previo acuerdo con el
            USUARIO respecto al importe adicional a pagar, la fecha y hora de
            entrega. La zona de reparto es limitada e informada por el operador
            de ENTRADA YA en la llamada. ENTRADA YA no realizará devoluciones
            por cargo de delivery de ser el caso que la persona autorizada por
            el USUARIO no se encuentre presente al momento de la entrega de
            la(s) entrada(s), siendo responsabilidad del USUARIO recoger
            oportunamente la entrada en los puntos de venta de ENTRADA YA previo
            pago de las mismas. Los puntos de venta de ENTRADA YA se encuentran
            publicados en el PORTAL.
            <br />
            <br />
            3.9 EVENTOS VIRTUALES
            <br />
            <br />
            Los eventos virtuales, son realizados en un grupo de Facebook o zoom
            (esto lo elige el productor de cada espectáculo). Los pasos para
            ingresar al show están indicados en la página web, descripción del
            evento de cada show y correo electrónico que recibe al comprar la
            entrada, ENTRADA YA, y EL PROMOTOR de los shows, no se hacen
            responsable si el público no sigue los pasos indicados para ingresar
            al mismo a tiempo, o no ingresa, por lo que no hay devoluciones para
            las personas que no sigan los pasos de ingreso. La devolución solo
            es factible en caso de cancelación de show.
            <br />
            En caso los clientes ingresen al grupo de facebook a ultima hora,
            ocasionando este congestion de la pagina, se les podra aceptar hasta
            48 horas despues del evento, esto es sin opcion a reclamo, ya que
            las indicaciones y canales de comunicacion estan desde el dia 1.
            <br />
            <br />
            <br />
            PROTECCIÓN DE DATOS PERSONALES
            <br />
            <br />
            Para adquirir entradas en el PORTAL el USUARIO registrado deberá
            facilitar determinados datos de carácter personal.
            <br />
            <br />
            De conformidad con la Ley N° 29733, Ley de Protección de Datos
            Personales, desde el momento de su ingreso y/o utilización al PORTAL
            el USUARIO da expresamente su consentimiento para el tratamiento de
            los datos personales que sean facilitados o que se faciliten a
            través de su ingreso al portal o por cualquier medio.
            <br />
            <br />
            Los datos personales que el USUARIO registre en el PORTAL serán
            incorporados a la base de datos de titularidad de ENTRADA YA. Estos
            datos serán utilizados en la gestión de los productos y/o servicios
            solicitados y/o contratados por el USUARIO.
            <br />
            <br />
            Asimismo, el titular de los datos personales autoriza a ENTRADA YA a
            utilizar sus datos personales, incluyendo datos sensibles, que
            hubieran sido proporcionados directamente a ENTRADA YA, aquellos que
            pudieran encontrarse en fuentes accesibles para el público o los que
            hayan sido obtenidos de terceros; para tratamientos que supongan
            desarrollo de acciones para la prestación de los servicios brindados
            por ENTRADA YA, así como la remisión (vía medio físico, electrónico
            o telefónico) de publicidad, información u ofertas (personalizadas o
            generales) de productos y/o servicios de ENTRADA YA y/o de otras
            empresas en la que ENTRADA YA sea accionista. Para tales efectos, el
            titular de los datos personales autoriza a ESVIDAL la cesión o
            comunicación de sus datos personales, a dichas empresas.
            <br />
            <br />
            Se informa al titular de los datos personales, USUARIO, que puede
            revocar la autorización para el tratamiento de sus datos personales
            en cualquier momento, de conformidad con lo previsto en la Ley de
            Protección de Datos Personales (Ley No. 29733) y su Reglamento
            (Decreto Supremo No. 003-2013-JUS). Para ejercer este derecho, o
            cualquier otro previsto en dichas normas, el titular de datos
            personales podrá presentar su solicitud a través
            de info@entradaya.com.
            <br />
            <br />
            El USUARIO titular de la cuenta es responsable de no mantener su
            sesión activa en un dispositivo al cual otro usuario o persona
            podría tener acceso. Exceptuando los casos que la Ley imponga
            expresamente lo contrario, y exclusivamente con la medida y
            extensión en que lo imponga, el PORTAL no garantiza ni asume
            responsabilidad alguna respecto a los posibles daños y perjuicios
            causados por el uso y utilización de la información, datos y
            servicios del PORTAL por terceros ajenos al titular de los datos.
            <br />
            <br />
            ATENCIÓN DE RECLAMOS
            <br />
            <br />
            Todo reclamo o queja será atendido dentro de los 30 días calendario
            de presentado. Dicho plazo será extendido por otro igual si el
            reclamo o queja lo justifican, previa comunicación al USUARIO antes
            de la culminación del plazo inicial. El reclamo o queja será
            presentado a través del formulario del Libro de Reclamaciones
            ubicado en el PORTAL de ENTRADA YA (www.entradaya.com).
            <br />
            <br />
            DOMICILIO
            <br />
            <br />
            Para los efectos del presente contrato, el USUARIO señala que
            cualquier comunicación o notificación se realice al correo
            electrónico registrado a través del PORTAL o en su defecto al
            domicilio que se indique en la base del RENIEC o SUNAT. El domicilio
            comercial de ENTRADA YA es aquel que se muestra en
            [www.entradaya.com]. El cambio de domicilio de cualquiera de las
            partes contratantes no surtirá efecto si dicho cambio no ha sido
            registrado a través del PORTAL.
            <br />
            <br />
            APLICACIÓN SUPLETORIA DE LA LEY
            <br />
            <br />
            Estos Términos y Condiciones de Uso se rigen por la ley peruana, y
            cualquier divergencia o discrepancia derivada de su interpretación,
            aplicación y ejecución será sometida previamente a una conciliación
            extrajudicial u cualquier otro medio alternativo de solución de
            conflicto.
            <br />
            <br />
            8.      EVENTOS PRESENCIALES CON PROTOCOLO COVID
            <br />
            <br />
            8.1 El usuario se compromete a seguir las normas de cada
            espectáculo, venue, teatro o local donde se organice el mismo.
            <br />
            <br />
            8.2 En caso de los eventos con orden de llegada y/o eventos
            enumerados o por sectores, Si el cliente llega al recinto luego que
            el espectáculo haya dado inicio, no es responsabilidad del
            ORGANIZADOR o ENTRADA YA la ubicación que se le asigne. Por
            comodidad de los clientes, el ingreso es hasta 5 minutos después de
            iniciada la función, luego de dicho tiempo de espera máximo, el
            organizador está autorizado a ubicarlo en un sitio donde no
            perjudique el desarrollo del espectáculo y no genere incomodidad en
            los asistentes.
            <br />
            <br />
            8.3 En caso de no cumplir con los protocolos de bio seguridad, o
            tener algún síntoma relacionado al covid19, tanto fiebre o tos, ni
            el ORGANIZADOR ni ENTRADA YA se hacen responsables de que le brinden
            accesos al show , por ende la pérdida de su entrada es instantánea
            sin opción a reembolso.
            <br />
            <br />
            8.4 Estimados clientes, en caso la productora del evento solicite
            que llene una declaración jurada para confirmar su compra, tendrá
            que llenarlo para poder ingresar al evento .
          </div>
        </div>
      </>
    );
  }
  if (source === "acercadenosotros") {
    return (
      <>
        <div className="col-12 d-flex">
          <img src={acercadenosotros} alt="" style={{ width: "100%" }}></img>
        </div>
        <div
          className="col-12 d-flex justify-content-center"
          style={{ backgroundColor: "#d9d9d9" }}
        >
          <div className="col-sm-10 col-12 league-spartan-400 po-a">
            <b style={{ color: "#ff0043" }}>Entradas Pe</b> es una plataforma
            dedicada al mundo de venta de boletos que conecta personas con los
            mismos intereses permitiendo a usuarios y a empresas la creación,
            difusión, gestión y cobro de eventos en Lima y en todo el país de
            manera sencilla y eficaz.
            <br />
            <br />
            Con <b style={{ color: "#ff0043" }}> Entradas Pe</b> podrán vender
            entradas y comenzar a crear experiencias compartiéndolas a través de
            las redes sociales. La idea es que puedan crear su evento en 1
            minuto, difundirlo a todas las redes social, por mail, y mientras se
            viraliza puedan vender entradas en el instante. Todos salen ganando,
            los organizadores llenan su aforo y los asistentes enterándose y
            participando con sus amigos a los diversos eventos de la cuidad.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}>Entradas Pe.com </b> es la forma más
            fácil de crear una conexión entre los organizadores y los asistentes
            apoyándose en la tecnología para convertir a los fans de conciertos,
            espectáctulos, fiestas, charlas, conferencias o eventos
            profesionales en los mejores relacionistas públicos de tu evento.
            <br />
            <br />
            Para los usuarios, es un enfoque completamente nuevo, estamos
            reinventando la manera de descubrir eventos en tu ciudad. Hay tantas
            cosas para hacer que quedarte siempre en casa sentado es una opción
            poco atractiva. No importa que estés buscando en
            <b style={{ color: "#ff0043" }}> www.EntradasPe.com</b>
            encontrarás todos los eventos para hacer en tu ciudad.
            <br />
            <br />
            Estamos seguros que en tu cuidad hay gente que comparte tus mismos
            intereses y creemos que reunirte con ellos es la mejor manera de
            aprender, crecer, compartir e inspirarte. Joinnus te permite
            encontrar y asistir a los eventos que sean de tu interés conectando
            con otras personas que comparten tu pasión.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}> Entradas Pe </b> fomenta a que
            descubras diversos eventos en tu cuidad, todas clasificadas bajo
            diferentes categorías desde eventos de arte y cultura, eventos para
            niños, charlas y conferencias, conciertos y toda clase de
            entretenimiento hasta eventos de ayuda social. Mucha¡os de estos
            eventos pueden transformarte, acompáñanos a crear un mundo donde te
            animamos a probar cosas nuevas, a salir, a disfrutar de lo que te
            apasione construyendo comunidades que compartan intereses afines.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}>Entradas Pe </b>utiliza el mundo
            virtual para conectar personas en el mundo real. ¡Conéctate para
            desconectarte!
            <br />
            Empieza a usar Joinnus y descubre increíbles eventos y personas.
          </div>
        </div>
      </>
    );
  }
  if (source === "preguntasfrecuentes") {
    return (
      <>
        <div className="col-12 d-flex">
          <img src={preguntasfrecuentes} alt="" style={{ width: "100%" }}></img>
        </div>
        <div
          className="col-12 d-flex justify-content-center"
          style={{ backgroundColor: "#d9d9d9" }}
        >
          <div className="col-sm-10 col-12 league-spartan-400 po-a">
            <b style={{ color: "#ff0043" }}>
              Soy el titular de la compra y no puedo ir al show, ¿qué debo
              hacer?
            </b>
            <br />
            <br />
            Es posible realizar una transferencia de entrada/s. Quién asista con
            tu/s entrada/s debe presentar junto con la/s misma/s una copia de tu
            DNI.
            <br />
            <br /> 
            <b style={{ color: "#ff0043" }}>
              Soy el comprador y un asistente no puede ir al show, ¿es necesario
              el cambio de entrada o puedo transferirla?
            </b>
            <br />
            <br />
            No es necesario el cambio. Podes transferir la entrada de ese
            asistente a otra persona. Si van a ingresar juntos/as no olvides
            llevar tu DNI para asegurarnos que la transferencia de la entrada es
            correcta. En caso que no puedan ingresar juntos/as que la otra
            persona lleve una copia de tu DNI para presentarlo con la entrada
            que fue transferida.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}>
              ¿Debo realizar canje de entradas?
            </b>
            <br />
            <br />
            No, todas las entradas adquiridas desde el sitio son válidas para
            ingresar.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}>
              ¿Qué debo presentar al momento del control de acceso?
            </b>
            <br />
            <br />
            Para poder ingresar sin inconvenientes debes presentar tu entrada
            impresa o mostrando el código QR del PDF de la misma desde tu
            celular. No olvides llevar tu DNI para corroborar los datos ante
            cualquier inconveniente.
            <br />
            <br />
            <b style={{ color: "#ff0043" }}>
              ¿Cómo funciona el control de acceso?
            </b>
            <br />
            <br />
            Realizamos un escaneo de los códigos QR de las entradas para
            validarlas y evitar la duplicidad de la misma ya que cada entrada es
            única.
          </div>
        </div>
      </>
    );
  }
  return <></>;
};
