import React from "react";
import { Header, Footer } from "../components/global/Components";
import { Policy } from "../components/policy/Components";
import { useParams } from "react-router-dom";
const Policies = () => {
  const { policy } = useParams();
  return (
    <>
      <Header source="naked" />
      <Policy source={policy} />
      <Footer source="naked" />
    </>
  );
};

export default Policies;
