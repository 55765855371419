import React from "react"
import GetIP from "../components/IPServer";
import SmallLoading from "../components/SmallLoading";
import { createRoot } from 'react-dom/client'
import { ticket } from "fontawesome";
const Transferir = () => {
    const handleSubmit = async (event) => {
        event.preventDefault();
        createRoot(document.getElementById('result')).render(<SmallLoading></SmallLoading>)
        const ticketId = event.target[0].value.split("-")
        if (ticketId[0] != "001001") {
            document.getElementById('result').innerHTML = "Datos no validos";
            return;
        }
        const cel = event.target[1].value
        const bankAccount = event.target[2].value
        const ammount = event.target[3].value
        let response = await fetch(`${GetIP}?options=transferirTicket&ticketId=${ticketId[1]}&cel=${cel}&collectionName=tickets`);
        if (!response.ok) {
            document.getElementById('result').innerHTML = "Datos no validos"
            return;
        }
        let jsonResponse = await response.json()
        if (!jsonResponse.paid) {
            document.getElementById('result').innerHTML = "Entrada no pagada"
            return;
        }
        if (jsonResponse.used) {
            document.getElementById('result').innerHTML = "Entrada usada"
            return;
        }
        if (jsonResponse.dtTransferDone != "") {
            document.getElementById('result').innerHTML = "Entrada ya transferida"
            return;
        }

        const data = {
            options: "transfer",
            id: jsonResponse._id,
            document: {
                bankAccount: bankAccount,
                ammount: ammount
            }
        }
        response = await fetch(`${GetIP}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
        if (response.ok) {
            document.getElementById('result').innerHTML = "Se ha enviado un SMS con los datos de la transferencia"
            return;
        }
        jsonResponse = await response.json()
        document.getElementById('result').innerHTML = "No se pudo generar un codigo de transferencia, intente nuevamente"
        return;

    }

    return <>
        <div className='container d-grid justify-content-center p-0' style={{ marginTop: '65px' }}>
            <div className='h4 text-center'> Transfiere tu entrada ingresando los siguientes datos:</div>
            <div className='container py-2 d-grid' style={{ background: '#e5e5e5', borderRadius: '15px', maxWidth: '400px' }}>
                <form onSubmit={handleSubmit}>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Numero de entrada</label>
                        <input title='Debe ingresar el numero de su entrada' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={12} maxLength={12}></input>
                    </div>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Numero de celular</label>
                        <input title='Debe ingresar el numero de su celular' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={9} maxLength={9}></input>
                    </div>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Cuenta bancaria</label>
                        <input title='Debe ingresar la cuenta en donde recibira el dinero' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={8}></input>
                    </div>
                    <div className='container d-grid my-3'>
                        <label className='my-2'>Monto (S/.)</label>
                        <input title='Debe ingresar el monto que cobrara por la entrada' input='text' format='[0-9]^' required={true} placeholder='' className='text-center' minLength={1}></input>
                    </div>
                    <div className='d-grid justify-content-center my-3'>
                        <h6 id='result' className=" hh6 text-center lh-lg"> </h6>
                    </div>
                    <div className='d-grid justify-content-center my-3'>
                        <button className='btn btn-danger'>Consultar</button>
                    </div>
                </form>
            </div>
        </div>
    </>
}

export default Transferir;