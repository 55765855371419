import { Routes, Route, BrowserRouter } from "react-router-dom";
import Event from "./pages/Event";
import Checkout from "./pages/Checkout";
import Home from "./pages/Home";
import Verificar from "./pages/Verificar";
import Administration from "./pages/Administration";
import Prepaid from "./pages/Prepaid";
import Paid from "./pages/Paid";
import Transferir from "./pages/Transferir";
import Recibir from "./pages/Recibir";
import Login from "./pages/Login";
import QRScanner from "./pages/QRScanner";
import Policies from "./pages/Policies";
export default function App() {
  const isCheckoutRoute = (pathname) => pathname.startsWith("/checkout");
  const isSacannerRoute = (pathname) => pathname.startsWith("/escaner");
  return (
    <>
      <main className="">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/eventos/:eventId" element={<Event />} />
            <Route path="/checkout/:hexId" element={<Checkout />} />
            <Route path="/prepaid/:userId" element={<Prepaid />} />
            <Route path="/paid/:userId" element={<Paid />} />
            <Route path="/administrar" element={<Administration />} />
            <Route path="/administrar/verificar" element={<Verificar />} />
            <Route path="/administrar/transferir" element={<Transferir />} />
            <Route path="/administrar/recibir" element={<Recibir />} />
            <Route path="/escaner" element={<Login />} />
            <Route path="/escaner/main" element={<QRScanner />} />
            <Route path="/politicas/:policy" element={<Policies />} />
          </Routes>
        </BrowserRouter>
      </main>
    </>
  );
}
