import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/Fonts.css";
import "../../styles/Styles.css";
import "../../styles/Stylespe.css";
import cine1 from "../../assets/cine1.png";
import cine2 from "../../assets/cine2.png";
import cine1_mobile from "../../assets/baresmobile.png";
import cine2_mobile from "../../assets/cinesmobile.png";
import search from "../../assets/search.png";
import lomaschevere from "../../assets/lomaschevere.png";
import cardlogo from "../../assets/cardlogo.png";
import calendar from "../../assets/calendar.png";
import ciudad from "../../assets/icono-02.png";
import fecha from "../../assets/icono-03.png";
import categoria from "../../assets/icono-01.png";

export const Carousel = (props) => {
  const { bannerRecords } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: function (i) {
      return <div className="custom-dot" />;
    },
  };
  return (
    <>
      {/* Para pantalla de tamaño sm (576px) o superior */}
      <div className="position-relative d-sm-block d-none">
        <Slider {...settings}>
          {(() => {
            const elements = [];
            for (let i = 0; i < bannerRecords.length; i++) {
              const idx = i;
              console.log(bannerRecords[idx].source);
              if (bannerRecords[idx].source === "pc") {
                elements.push(
                  <>
                    <div className="carousel-pe d-sm-flex d-none">
                      <img
                        className="imagecarousel-pe"
                        src={bannerRecords[idx].img}
                        alt="Imagen 1"
                      />
                    </div>
                  </>
                );
              }
            }
            return elements;
          })()}
        </Slider>
      </div>
      {/* Para pantalla de tamaño sm (576px) o inferior */}
      <div className="position-relative d-sm-none d-block">
        <Slider {...settings}>
          {(() => {
            const elements = [];
            for (let i = 0; i < bannerRecords.length; i++) {
              const idx = i;
              console.log(bannerRecords[idx].source);
              if (bannerRecords[idx].source === "mobile") {
                elements.push(
                  <>
                    <div className="carousel-pe d-sm-none d-flex">
                      <img
                        className="imagecarousel-pe"
                        src={bannerRecords[idx].img}
                        alt="Imagen 1"
                      />
                    </div>
                  </>
                );
              }
            }
            return elements;
          })()}
        </Slider>
      </div>
    </>
  );
};

export const Cine = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: function (i) {
      return <div className="custom-dot" />;
    },
  };
  return (
    <>
      <div className="position-relative d-sm-block d-none">
        <Slider {...settings}>
          <div className="subcine-pe d-flex">
            <img className="w-100" src={cine1} alt="Imagen 1" />
          </div>
          <div className="subcine-pe d-flex">
            <img className="w-100" src={cine2} alt="Imagen 1" />
          </div>
        </Slider>
      </div>

      <div className="position-relative d-sm-none d-block">
        <Slider {...settings}>
          <div className="subcine-pe d-flex">
            <img className="w-100" src={cine1_mobile} alt="Imagen 1" />
          </div>
          <div className="subcine-pe d-flex">
            <img className="w-100" src={cine2_mobile} alt="Imagen 1" />
          </div>
        </Slider>
      </div>
    </>
  );
};

export const Search = () => {
  return (
    <>
      <div className="col-12 d-sm-flex d-block search-pe">
        <div className="col-1"></div>
        <div className="col-sm-6 col-12 d-flex align-items-center">
          <div className="league-spartan-400 d-flex justify-content-center align-items-center searchdiv-pe">
            <img className="imgsearch-pe" src={search} alt=""></img>
            <div>&nbsp;&nbsp;Busca por artista, evento o lugar</div>
          </div>
        </div>
        <div className="col-1 "></div>
        <div className="col-sm-3 col-12 d-flex">
          <div className="col-sm-0 d-sm-none col-2"></div>
          <div className="col-sm-4 col-2 league-spartan-500 d-grid text-center justify-content-center fontsearch-pe">
            <div className="d-flex justify-content-center">
              <img src={ciudad} className="iconsearch-pe" alt=""></img>
            </div>
            <p>Fecha</p>
          </div>
          <div className="col-1 d-sm-none"></div>
          <div className="col-sm-4 col-2 league-spartan-500 d-grid text-center justify-content-center fontsearch-pe">
            <div className="d-flex justify-content-center">
              <img src={fecha} className="iconsearch-pe" alt=""></img>
            </div>
            <p>Categoria</p>
          </div>
          <div className="col-1 d-sm-none"></div>
          <div className="col-sm-4 col-2 league-spartan-500 d-grid text-center justify-content-center fontsearch-pe">
            <div className="d-flex justify-content-center">
              <img src={categoria} className="iconsearch-pe" alt=""></img>
            </div>
            <p>Ciudad</p>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};

export const Chevere = () => {
  return (
    <>
      <>
        <div className="col-12 d-flex justify-content-center chevere-pe">
          <div className="col-sm-1 col-0"></div>
          <div className="col-sm-5 col-12 league-spartan-600 d-flex">
            <img src={lomaschevere} className="imgchevere-pe" alt=""></img>
          </div>
          <div className="col-sm-6 col-0"></div>
        </div>
      </>
    </>
  );
};

export const EventCard = (props) => {
  const { records } = props;
  console.log(records.length);
  const [multiples, setMultiples] = useState(null);
  const [multiplesSmall, setMultiplesSmall] = useState(null);
  useEffect(() => {
    setMultiples(Math.floor(records.length / 3));
    setMultiplesSmall(Math.floor(records.length / 2));
  }, [records.length]);
  return (
    <>
      <div style={{ marginTop: "1.09vw" }}>
        <>
          {(() => {
            const elements = [];
            let i = 0;
            for (i = 0; i < multiples + 1; i++) {
              const currentIndex = i;
              elements.push(
                <>
                  <div
                    className="col-12 d-sm-flex d-none justify-content-start"
                    style={{ marginBottom: "1.3vw" }}
                  >
                    <div className="col-1"></div>
                    <div className="col-3">
                      {(() => {
                        if (records[currentIndex * 3] != null)
                          return (
                            <div
                              className="card-custom"
                              style={{
                                height: "28.12vw",
                                width: "22.91vw",
                                borderRadius: "20px",
                                overflow: "hidden",
                                boxShadow: "1px 2px 2px 2px rgba(0,0,0,0.5)",
                                marginLeft: "1.61vw",
                              }}
                              onClick={() => {
                                window.location.href = records[currentIndex * 3]
                                  .fullscreen
                                  ? "/contactanos"
                                  : `/eventos/${records[currentIndex * 3]._id}`;
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  height: records[currentIndex * 3].fullscreen
                                    ? "28.12vw"
                                    : "16.08vw",
                                  width: "22.91vw",
                                }}
                              >
                                <img
                                  src={records[currentIndex * 3].image}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  alt=""
                                ></img>
                              </div>

                              <div
                                className=""
                                style={{
                                  height: "11.03vw",
                                  width: "22.91vw",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center league-spartan-500"
                                  style={{
                                    height: "3.22vw",
                                    fontSize: "1.66vw",
                                    overflow: "visible",
                                    whiteSpace: "normal",
                                    //textOverflow: "ellipsis",
                                    marginTop: "1.19vw",
                                    paddingLeft: "0.72vw",
                                    paddingRight: "0.83vw",
                                  }}
                                >
                                  <img
                                    src={cardlogo}
                                    style={{ height: "2.4vw", width: "2.4vw" }}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      overflow: "visible",
                                      whiteSpace: "normal",
                                      lineHeight: "1.1", // Reducir el espacio entre líneas de texto
                                      //textOverflow: "ellipsis",
                                      width: "18vw",
                                      marginLeft: "0.47vw",
                                    }}
                                  >
                                    {records[currentIndex * 3].title}
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center league-spartan-300"
                                  style={{
                                    height: "2.5vw",
                                    backgroundColor: "#ffd6e1",
                                    paddingLeft: "0.625vw",
                                    paddingRight: "0.625vw",
                                    marginTop: "1.19vw",
                                    fontSize: "1.51vw",
                                  }}
                                >
                                  <img
                                    src={calendar}
                                    style={{
                                      height: "1.51vw",
                                      width: "1.77vw",
                                    }}
                                    alt=""
                                  ></img>
                                  &nbsp;{records[currentIndex * 3].date}
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "4.16vw",
                                    paddingLeft: "1.56vw",
                                    paddingRight: "1.56vw",
                                  }}
                                >
                                  <div
                                    className="league-spartan-300"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    Desde
                                  </div>
                                  <div
                                    className="league-spartan-600"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    &nbsp;s/&nbsp;
                                    {records[currentIndex * 3].price[0].toFixed(
                                      2
                                    )}
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      height: "2.13vw",
                                      width: "7.97vw",
                                      borderRadius: "20.5px",
                                      backgroundColor: "#ff0043",
                                      marginLeft: "5.20vw",
                                    }}
                                  >
                                    <div
                                      className="league-spartan-300"
                                      style={{
                                        color: "white",
                                        fontSize: "1.35vw",
                                        marginTop: "0.26vw",
                                      }}
                                    >
                                      Comprar
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })()}
                    </div>
                    <div className="col-4">
                      {(() => {
                        if (records[currentIndex * 3 + 1] != null)
                          return (
                            <div
                              className="card-custom"
                              style={{
                                height: "28.12vw",
                                width: "22.91vw",
                                borderRadius: "20px",
                                overflow: "hidden",
                                boxShadow: "1px 2px 2px 2px rgba(0,0,0,0.5)",
                                marginLeft: "4.89vw",
                              }}
                              onClick={() => {
                                window.location.href = records[
                                  currentIndex * 3 + 1
                                ].fullscreen
                                  ? "/contactanos"
                                  : `/eventos/${
                                      records[currentIndex * 3 + 1]._id
                                    }`;
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  height: records[currentIndex * 3 + 1]
                                    .fullscreen
                                    ? "28.12vw"
                                    : "16.08vw",
                                  width: "22.91vw",
                                }}
                              >
                                <img
                                  src={records[currentIndex * 3 + 1].image}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  alt=""
                                ></img>
                              </div>
                              <div
                                className=""
                                style={{
                                  height: "12.03vw",
                                  width: "22.91vw",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center league-spartan-500"
                                  style={{
                                    height: "3.22vw",
                                    fontSize: "1.66vw",
                                    overflow: "visible",
                                    whiteSpace: "normal",
                                    //textOverflow: "ellipsis",
                                    marginTop: "1.19vw",
                                    paddingLeft: "0.72vw",
                                    paddingRight: "0.83vw",
                                  }}
                                >
                                  <img
                                    src={cardlogo}
                                    style={{ height: "2.4vw", width: "2.24vw" }}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      overflow: "visible",
                                      whiteSpace: "normal",
                                      lineHeight: "1.1", // Reducir el espacio entre líneas de texto
                                      //textOverflow: "ellipsis",
                                      width: "18vw",
                                      marginLeft: "0.47vw",
                                    }}
                                  >
                                    &nbsp;
                                    {records[currentIndex * 3 + 1].title}
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center league-spartan-300"
                                  style={{
                                    height: "2.5vw",
                                    backgroundColor: "#ffd6e1",
                                    paddingLeft: "0.625vw",
                                    paddingRight: "0.625vw",
                                    marginTop: "1.19vw",
                                    fontSize: "1.51vw",
                                  }}
                                >
                                  <img
                                    src={calendar}
                                    style={{
                                      height: "1.51vw",
                                      width: "1.77vw",
                                    }}
                                    alt=""
                                  ></img>
                                  &nbsp;{records[currentIndex * 3 + 1].date}
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "4.16vw",
                                    paddingLeft: "1.56vw",
                                    paddingRight: "1.56vw",
                                  }}
                                >
                                  <div
                                    className="league-spartan-300"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    Desde
                                  </div>
                                  <div
                                    className="league-spartan-600"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    &nbsp;s/&nbsp;
                                    {records[
                                      currentIndex * 3 + 1
                                    ].price[0].toFixed(2)}
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      height: "2.13vw",
                                      width: "7.97vw",
                                      borderRadius: "20.5px",
                                      backgroundColor: "#ff0043",
                                      marginLeft: "5.20vw",
                                    }}
                                  >
                                    <div
                                      className="league-spartan-300"
                                      style={{
                                        color: "white",
                                        fontSize: "1.35vw",
                                        marginTop: "0.26vw",
                                      }}
                                    >
                                      Comprar
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })()}
                    </div>
                    <div className="col-3">
                      {(() => {
                        if (records[currentIndex * 3 + 2] != null)
                          return (
                            <div
                              className="card-custom"
                              style={{
                                height: "28.12vw",
                                width: "22.91vw",
                                borderRadius: "20px",
                                overflow: "hidden",
                                boxShadow: "1px 2px 2px 2px rgba(0,0,0,0.5)",
                                //marginLeft: "1.61vw",
                              }}
                              onClick={() => {
                                window.location.href = records[
                                  currentIndex * 3 + 2
                                ].fullscreen
                                  ? "/contactanos"
                                  : `/eventos/${
                                      records[currentIndex * 3 + 2]._id
                                    }`;
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  height: records[currentIndex * 3 + 2]
                                    .fullscreen
                                    ? "28.12vw"
                                    : "16.08vw",
                                  width: "22.91vw",
                                }}
                              >
                                <img
                                  src={records[currentIndex * 3 + 2].image}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  alt=""
                                ></img>
                              </div>
                              <div
                                className=""
                                style={{
                                  height: "12.03vw",
                                  width: "22.91vw",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center league-spartan-500"
                                  style={{
                                    height: "3.22vw",
                                    fontSize: "1.66vw",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    marginTop: "1.19vw",
                                    paddingLeft: "0.72vw",
                                    paddingRight: "0.83vw",
                                  }}
                                >
                                  <img
                                    src={cardlogo}
                                    style={{ height: "2.4vw", width: "2.24vw" }}
                                    alt=""
                                  ></img>
                                  <div
                                    style={{
                                      overflow: "visible",
                                      whiteSpace: "normal",
                                      lineHeight: "1.1", // Reducir el espacio entre líneas de texto
                                      //textOverflow: "ellipsis",
                                      width: "18vw",
                                      marginLeft: "0.47vw",
                                    }}
                                  >
                                    &nbsp;
                                    {records[currentIndex * 3 + 2].title}
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center league-spartan-300"
                                  style={{
                                    height: "2.5vw",
                                    backgroundColor: "#ffd6e1",
                                    paddingLeft: "0.625vw",
                                    paddingRight: "0.625vw",
                                    marginTop: "1.19vw",
                                    fontSize: "1.51vw",
                                  }}
                                >
                                  <img
                                    src={calendar}
                                    style={{
                                      height: "1.51vw",
                                      width: "1.77vw",
                                    }}
                                    alt=""
                                  ></img>
                                  &nbsp;{records[currentIndex * 3 + 2].date}
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "4.16vw",
                                    paddingLeft: "1.56vw",
                                    paddingRight: "1.56vw",
                                  }}
                                >
                                  <div
                                    className="league-spartan-300"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    Desde
                                  </div>
                                  <div
                                    className="league-spartan-600"
                                    style={{ fontSize: "1.46vw" }}
                                  >
                                    &nbsp;s/&nbsp;
                                    {records[
                                      currentIndex * 3 + 2
                                    ].price[0].toFixed(2)}
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      height: "2.13vw",
                                      width: "7.97vw",
                                      borderRadius: "20.5px",
                                      backgroundColor: "#ff0043",
                                      marginLeft: "5.20vw",
                                    }}
                                  >
                                    <div
                                      className="league-spartan-300"
                                      style={{
                                        color: "white",
                                        fontSize: "1.35vw",
                                        marginTop: "0.26vw",
                                      }}
                                    >
                                      Comprar
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })()}
                    </div>
                    <div className="col-1"></div>
                  </div>
                </>
              );
            }
            return elements;
          })()}
        </>
        <>
          <div
            className="col-12 d-sm-none d-flex"
            style={{
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              marginBottom: "2.22vw",
            }}
          >
            <div className="d-block">
              {(() => {
                const elementsSmall = [];
                let i = 0;
                for (i = 0; i < multiplesSmall + 1; i++) {
                  const currentIndex = i;
                  console.log(i);
                  elementsSmall.push(
                    <>
                      <div
                        className="d-flex"
                        style={{
                          height: "auto",
                          width: "145.83vw",
                          marginBottom: "6.17vw",
                        }}
                      >
                        {(() => {
                          if (records[currentIndex * 2] != null)
                            return (
                              <>
                                <div className="" style={{ width: "72.915vw" }}>
                                  <div
                                    style={{
                                      height: "72.22vw",
                                      width: "66.67vw",
                                      marginLeft: "4.17vw",
                                      marginRight: "2.08vw",
                                      borderRadius: "20px",
                                      overflow: "hidden",
                                      boxShadow:
                                        "1px 2px 2px 2px rgba(0,0,0,0.5)",
                                    }}
                                    onClick={() => {
                                      window.location.href = records[
                                        currentIndex * 2
                                      ].fullscreen
                                        ? "contactanos"
                                        : `/eventos/${
                                            records[currentIndex * 2]._id
                                          }`;
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center"
                                      style={{
                                        height: records[currentIndex * 2]
                                          .fullscreen
                                          ? "72.22vw"
                                          : "39.44vw",
                                      }}
                                    >
                                      <img
                                        src={records[currentIndex * 2].image}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        alt=""
                                      ></img>
                                    </div>
                                    <div
                                      className="d-flex align-items-center league-spartan-600"
                                      style={{
                                        height: "12.64vw",
                                        paddingLeft: "2.08vw",
                                        fontSize: "5.56vw",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <img
                                        src={cardlogo}
                                        style={{
                                          height: "5.56vw",
                                          width: "5.56vw",
                                        }}
                                        alt=""
                                      ></img>
                                      <div
                                        style={{
                                          marginTop: "1.15vw",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        &nbsp;{records[currentIndex * 2].title}
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex league-spartan-300 align-items-center"
                                      style={{
                                        height: "7.5vw",
                                        backgroundColor: "#ffd6e1",
                                        paddingLeft: "2.08vw",
                                        fontSize: "4.44vw",
                                      }}
                                    >
                                      <img
                                        src={calendar}
                                        style={{
                                          height: "4.58vw",
                                          width: "5.56vw",
                                        }}
                                        alt=""
                                      ></img>
                                      <div
                                        className="d-flex"
                                        style={{ marginTop: "1.15vw" }}
                                      >
                                        &nbsp;{records[currentIndex * 2].date}
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "12.64vw",
                                        paddingLeft: "2.08vw",
                                      }}
                                    >
                                      <div
                                        className="league-spartan-300"
                                        style={{ fontSize: "4.17vw" }}
                                      >
                                        Desde
                                      </div>
                                      <div
                                        className="league-spartan-600"
                                        style={{ fontSize: "4.17vw" }}
                                      >
                                        &nbsp;s/{" "}
                                        {records[currentIndex * 2].price[0]}
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          marginLeft: "15vw",
                                          fontSize: "3.89vw",
                                        }}
                                      >
                                        <div
                                          className="d-flex align-content-center justify-content-center league-spartan-400"
                                          style={{
                                            height: "6.67vw",
                                            width: "24.44vw",
                                            backgroundColor: "#ff0043",
                                            borderRadius: "20px",
                                            color: "white",
                                            paddingTop: "0.6vw",
                                          }}
                                        >
                                          Comprar
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                        })()}

                        {(() => {
                          if (records[currentIndex * 2 + 1] != null)
                            return (
                              <>
                                <div className="" style={{ width: "76.915vw" }}>
                                  <div
                                    style={{
                                      height: "72.22vw",
                                      width: "66.67vw",
                                      marginLeft: "6.09vw",
                                      marginRight: "4.17vw",
                                      borderRadius: "20px",
                                      overflow: "hidden",
                                      boxShadow:
                                        "1px 2px 2px 2px rgba(0,0,0,0.5)",
                                    }}
                                    onClick={() => {
                                      window.location.href = records[
                                        currentIndex * 2 + 1
                                      ].fullscreen
                                        ? "contactanos"
                                        : `/eventos/${
                                            records[currentIndex * 2 + 1]._id
                                          }`;
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center"
                                      style={{
                                        height: records[currentIndex * 2 + 1]
                                          .fullscreen
                                          ? "72.22vw"
                                          : "39.44vw",
                                      }}
                                    >
                                      <img
                                        src={
                                          records[currentIndex * 2 + 1].image
                                        }
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        alt=""
                                      ></img>
                                    </div>
                                    <div
                                      className="d-flex align-items-center league-spartan-600"
                                      style={{
                                        height: "12.64vw",
                                        paddingLeft: "2.08vw",
                                        fontSize: "5.56vw",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <img
                                        src={cardlogo}
                                        style={{
                                          height: "5.56vw",
                                          width: "5.56vw",
                                        }}
                                        alt=""
                                      ></img>
                                      <div
                                        style={{
                                          marginTop: "1.15vw",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        &nbsp;
                                        {records[currentIndex * 2 + 1].title}
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex league-spartan-300 align-items-center"
                                      style={{
                                        height: "7.5vw",
                                        backgroundColor: "#ffd6e1",
                                        paddingLeft: "2.08vw",
                                        fontSize: "4.44vw",
                                      }}
                                    >
                                      <img
                                        src={calendar}
                                        style={{
                                          height: "4.58vw",
                                          width: "5.56vw",
                                        }}
                                        alt=""
                                      ></img>
                                      <div
                                        className="d-flex"
                                        style={{ marginTop: "1.15vw" }}
                                      >
                                        &nbsp;
                                        {records[currentIndex * 2 + 1].date}
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "12.64vw",
                                        paddingLeft: "2.08vw",
                                      }}
                                    >
                                      <div
                                        className="league-spartan-300"
                                        style={{ fontSize: "4.17vw" }}
                                      >
                                        Desde
                                      </div>
                                      <div
                                        className="league-spartan-600"
                                        style={{ fontSize: "4.17vw" }}
                                      >
                                        &nbsp;s/{" "}
                                        {records[currentIndex * 2 + 1].price[0]}
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          marginLeft: "15vw",
                                          fontSize: "3.89vw",
                                        }}
                                      >
                                        <div
                                          className="d-flex align-content-center justify-content-center league-spartan-400"
                                          style={{
                                            height: "6.67vw",
                                            width: "24.44vw",
                                            backgroundColor: "#ff0043",
                                            borderRadius: "20px",
                                            color: "white",
                                            paddingTop: "0.6vw",
                                          }}
                                        >
                                          Comprar
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                        })()}
                      </div>
                    </>
                  );
                }
                return elementsSmall;
              })()}
            </div>
          </div>
        </>
      </div>
    </>
  );
};
