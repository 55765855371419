import React from "react";
import { FidgetSpinner } from "react-loader-spinner";
const Loading = () => {
    return <>
        <div className='d-grid justify-content-center align-items-center' style={{ height: "500px" }}>
            <FidgetSpinner
                visible={true}
                height="200"
                width="200"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                backgroundColor='red'
            />
        </div>
    </>
}



export default Loading;