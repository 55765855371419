import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetIP from "../components/IPServer";
import PdfDocument from "../components/StepsPDF";
import TicketPDF from "../components/TicketPDF";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import { Loading } from "../components/global/Components";
const Prepaid = () => {
  const { userId } = useParams();
  const [ticketRecords, setTicketRecords] = useState([]);
  const [eventRecord, setEventRecord] = useState();
  const [userRecord, setUserRecord] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(
    () => {
      async function fetchData() {
        const userRecord = await (
          await fetch(
            `${GetIP}?options=getone&collectionName=users&id=${userId}`
          )
        ).json();
        if (userRecord) {
          setUserRecord(userRecord);
          const eventRecord = await (
            await fetch(
              `${GetIP}?options=getone&collectionName=events&id=${userRecord.eventId}`
            )
          ).json();
          if (eventRecord) {
            setEventRecord(eventRecord);
            const ticketRecords = await (
              await fetch(
                `${GetIP}?options=getmany&collectionName=tickets&checkoutCode=${userRecord.checkoutCode}`
              )
            ).json();
            if (ticketRecords.length > 0) {
              setTicketRecords(ticketRecords);
              setLoading(false);
            }
          }
        }
      }
      fetchData();
      return;
    },
    userId,
    [ticketRecords.length]
  );
  if (loading)
    return (
      <>
        <Loading></Loading>
      </>
    );
  return (
    <>
      <div
        className=" h6 container d-grid justify-content-center align-items-center text-center"
        style={{ marginTop: "160px" }}
      >
        Si aun no ha realizado el pago recuerde hacerlo siguiendo las siguientes
        instrucciones:
      </div>
      <div
        className="container d-grid justify-content-center align-items-center "
        style={{ marginTop: "50px", marginBottom: "160px" }}
      >
        <PDFDownloadLink
          document={
            <PdfDocument
              userRecord={userRecord}
              eventRecord={eventRecord}
              checkoutCode={userRecord.checkoutCode}
            />
          }
          fileName="Instrucciones de pago.pdf"
          style={{ textDecoration: "none", color: "white" }}
          className="btn jn-buy-button ju-waves rounded-pill"
        >
          {({ blob, url, loading, error }) =>
            loading ? "Cargando..." : "Descargar instrucciones de pago"
          }
        </PDFDownloadLink>
      </div>

      {/* <div className=' h6 container d-grid justify-content-center align-items-center text-center' style={{ marginTop: "80px" }}>
                Puede descargar sus entradas desde el siguiente enlace:
            </div>
            <div className='container d-grid justify-content-center align-items-center my-2'>
                <PDFDownloadLink
                    document={
                        <TicketPDF
                            userRecord={userRecord}
                            eventRecord={eventRecord}
                            ticketRecords={ticketRecords}
                        />
                    }

                    fileName="Entradaspe - tickets.pdf"
                    style={{ textDecoration: "none", color: "white" }} className="btn jn-buy-button ju-waves rounded-pill" >
                    {({ blob, url, loading, error }) =>
                        loading ? 'Cargando...' : 'Descargar entradas'
                    }
                </PDFDownloadLink>
            </div> */}
    </>
  );
};

export default Prepaid;
