import BlurImg from "../components/BlurImg";
import InfoEvent from "../components/InfoEvent";
import TicketEvent from "../components/TicketEvent";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import GetIP from "../components/IPServer";
import { Header, Footer, Loading } from "../components/global/Components";
import {
  UpperComponent,
  LowerComponent,
} from "../components/events/Components";
function Event() {
  const { eventId } = useParams();
  const [eventData, setEventData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      const filter = encodeURIComponent(JSON.stringify({ _id: eventId }));
      const data = await (
        await fetch(`${GetIP}?collection=events&filter=${filter}`)
      ).json();
      if (data) {
        setLoading(false);
      }
      setEventData(data[0]);
    }
    fetchData();
    return;
  }, [eventId]);

  if (loading) return <Loading />;

  //const dtEvent = new Date(eventData.datetime).getTime();
  //if (dtEvent - new Date().getTime() < 1000 * 60 * 60 * 24) return <></>;
  return (
    <>
      <Header source="home" />
      <UpperComponent eventData={eventData} />
      <LowerComponent eventData={eventData} />
      <Footer source="home" />
    </>
  );
}

export default Event;
