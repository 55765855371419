import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import GetIP from "../components/IPServer";
import { QrScanner, useMediaDevices } from "@yudiel/react-qr-scanner";
import SmallLoading from "../components/SmallLoading";
import { createRoot } from "react-dom/client";
import { scanner, ticket } from "fontawesome";
const QRScanner = () => {
  const location = useLocation();
  let scannerRecord = location.state?.scannerRecord;
  if (!scannerRecord) window.location.href = "/escaner";
  useEffect(() => {
    const lastLogin = async () => {
      const filter = encodeURIComponent(
        JSON.stringify({ _id: scannerRecord._id })
      );
      let response = await fetch(`${GetIP}?event=scaneners&&filter=${filter}`);
      scannerRecord = await response.json();
      //console.log((new Date().getTime() - scannerRecord.lastLogin))
      if (new Date().getTime() - scannerRecord.lastLogin > 20 * 1000)
        window.location.href = "/escaner";
    };
    return lastLogin;
  }, [scannerRecord]);
  useEffect(() => {
    const updateLastLogin = async () => {
      let data = {
        options: "newLogin",
        id: scannerRecord._id,
        lastLogin: new Date().getTime(),
      };
      try {
        const response = await fetch(`${GetIP}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          return;
        }
        console.log(".");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const intervalId = setInterval(updateLastLogin, 10000);
    return () => clearInterval(intervalId);
  }, [scannerRecord]);

  ////////////////////////////////////////
  const [availableCameras, setAvailableCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [loading, setLoading] = useState(true);

  const handleCameraChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  const constraints = {
    deviceId: { exact: selectedCamera },
  };

  const [permissionGranted, setPermissionGranted] = useState(false);

  useEffect(() => {
    const requestCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setPermissionGranted(true);
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setAvailableCameras(cameras);
        setSelectedCamera(cameras.length > 0 ? cameras[0].deviceId : "");
      } catch (error) {
        console.error("Error al obtener permisos de cámara:", error);
      }
    };

    requestCameraPermission();
  }, []);

  ///////////////////////////////////////

  const handleResult = async (result) => {
    document.getElementById("result").style = "";
    createRoot(document.getElementById("result")).render(
      <SmallLoading></SmallLoading>
    );
    const filter = encodeURIComponent(JSON.stringify({ _id: result }));
    let response = await fetch(`${GetIP}?collection=tickets&filter=${filter}`);
    document.getElementById("result").style = "background: red";
    if (!response.ok) {
      document.getElementById("result").innerHTML =
        "Ticket no encontrado en base de datos";
      return;
    }
    let ticketRecord = await response.json();
    ticketRecord = ticketRecord[0];
    if (!ticketRecord.paid) {
      document.getElementById("result").innerHTML = "Entrada no pagada";
      return;
    }
    if (ticketRecord.dtUsed != "" || ticketRecord.used) {
      const usedDate = new Date(ticketRecord.dtUsed);
      const horaLima = usedDate.toLocaleString("es-PE", {
        timeZone: "America/Lima",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      document.getElementById(
        "result"
      ).innerHTML = `Entrada usada: ${horaLima}`;
      return;
    }
    if (ticketRecord.type != scannerRecord.type) {
      document.getElementById(
        "result"
      ).innerHTML = `Debe ingresar por la entrada ${ticketRecord.type}`;
      return;
    }
    if (ticketRecord.dtTransferDone != "") {
      document.getElementById(
        "result"
      ).innerHTML = `Entrada invalida, ha sido transferida`;
      return;
    }

    let data = {
      options: "ticketUsed",
      id: ticketRecord._id,
      username: scannerRecord.username,
    };
    response = await fetch(`${GetIP}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      document.getElementById("result").style = "background: gray";
      document.getElementById(
        "result"
      ).innerHTML = `Error de conexion, escanear nuevamente`;
      return;
    }
    document.getElementById("result").style = "background: lime";
    document.getElementById(
      "result"
    ).innerHTML = `Entrada valida<br>${ticketRecord.type}`;
  };

  const handleError = async (error) => {
    console.log(error);
  };
  return (
    <>
      <QrScanner
        scanDelay={2000}
        onDecode={(result) => handleResult(result)}
        onError={(error) => handleError(error?.message)}
        constraints={constraints}
      />

      <div className="container d-flex justify-content-center mt-2">
        <label htmlFor="cameraSelect">Camara: &nbsp;</label>
        <select
          id="cameraSelect"
          value={selectedCamera}
          onChange={handleCameraChange}
        >
          {availableCameras.map((camera) => (
            <option key={camera.deviceId} value={camera.deviceId}>
              {camera.label || `Cámara ${camera.deviceId}`}
            </option>
          ))}
        </select>
      </div>

      <div className="container d-grid justify-content-center mt-2">
        <div className="container h4 text-center" id="result"></div>
      </div>
    </>
  );
};
export default QRScanner;
